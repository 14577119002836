/* Light Mode */
.light-mode {
    background-color: #F2F2F2;
    color: #333333;
}

.light-mode h1.privacy-policy-heading,
.light-mode h2.section-heading,
.light-mode h3.sub-section-heading,
.light-mode h4.sub-sub-section-heading {
    color: #003366;
}

.light-mode .privacy-policy-last-updated {
    color: #666666;
}

.light-mode .privacy-policy-introduction,
.light-mode .section-summary,
.light-mode .sub-section-content,
.light-mode .sub-sub-section-content {
    color: #333333;
}

/* Dark Mode */
.dark-mode {
    background-color: #232323;
    color: #CCCCCC;
}

.dark-mode h1.privacy-policy-heading,
.dark-mode h2.section-heading,
.dark-mode h3.sub-section-heading,
.dark-mode h4.sub-sub-section-heading {
    color: #6EC6FF;
}

.dark-mode .privacy-policy-last-updated {
    color: #B0B0B0;
}

.dark-mode .privacy-policy-introduction,
.dark-mode .section-summary,
.dark-mode .sub-section-content,
.dark-mode .sub-sub-section-content {
    color: #CCCCCC;
}
