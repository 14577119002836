.transcription-dashboard {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer); /* Assuming this is a defined variable in your CSS */
    color: var(--color-text); /* Assuming this is a defined variable in your CSS */
}


.upload-controls{
    background: #121212; /* Almost black background */
    color: #f8f9fa; /* Brighter off-white text */
    border-left: 5px solid #28a745; /* Green accent border */
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-file-upload {
    background-color: #007bff; /* Blue accent */
    display: inline-block;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 28px;
    border: none;
}

.custom-file-upload input[type='file'] {
    display: none;
}


.progress-bar-container {
    width: 100%;
    background-color: #2c3e50; /* Darker grey */
    border-radius: 4px;
    margin: 10px 0;
}

.progress-bar {
    height: 20px;
    background-color: #28a745; /* Green progress */
    border-radius: 4px;
    transition: width 0.5s ease-in-out;
}

.user-credits-container {
    background: #2b2b2b; /* Contemporary grey */
    color: #dcdcdc; /* Off-white text */
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-left: 5px solid #28a745; /* Green accent border */
    font-size: 50px;
}

.user-info h1, .credits-info h1 {
    margin: 0 10px;
}

.sign-out-button {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    padding: 0.5rem 1rem;
    margin-left: 10px; /* Extra space before the button */
    border-radius: 5px;
    border: none;
    background-color: #FF4820;
    color: #fff;
    cursor: pointer;
}

.axn__transcription_buy-credits-button{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    padding: 0.5rem 1rem;
    margin-left: 10px; /* Extra space before the button */
    border-radius: 5px;
    border: none;
    background-color: #FF4820;
    color: #fff;
    cursor: pointer;

}
.search-bar-container {
    background: #2b2b2b; /* Contemporary grey, similar to user-credits-container */
    color: #2b2b2b; /* Off-white text */
    padding: 0.5rem 1rem; /* Adjust padding */
    border-radius: 20px; /* Rounded edges for a sleek look */
    margin-bottom: 1rem; /* Spacing below the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    display: flex;
    justify-content: center; /* Center align the search bar */
    border-left: 5px solid #007bff; /* Blue accent border for prominence */
    position: sticky; /* Make it stick to the top when scrolling */
    top: 0; /* Stick to the top */
    z-index: 10; /* Ensure it's above other elements */
}

.search-bar {
    width: 80%; /* Occupying most of the container */
    padding: 0.6rem; /* Slightly larger padding for a balanced look */
    border-radius: 20px; /* Rounded edges */
    border: none; /* No border */
    outline: none; /* Remove outline to make it sleek */
    font-size: 1rem; /* Readable font size */
    color: inherit; /* Inherit text color from the container */
    background-color: #ffffff; /* White background for the input */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Outer shadow for depth */
    transition: transform 0.3s ease; /* Smooth transition for the transform effect */
}

.search-bar:focus {
    transform: scale(1.05); /* Slightly enlarge on focus */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4); /* Darker shadow on focus for visibility */
}

.transcription-dashboard h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 24px; /* Adjust font size as needed */
    color: #fff; /* White color for better readability */
    margin: 0; /* Remove default margin */
    text-align: center; /* Center align the text */
}


.transcription-dashboard h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    color: #fff; /* Adjust color as needed */
    margin-bottom: 2rem;
}

.transcription-dashboard button {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #FF4820; /* Button background color */
    color: #fff; /* Button text color */
}

.transcription-dashboard input[type='file'] {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 28px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    border: 2px solid var(--color-footer);
    background: var(--color-footer);
    color: #3D6184; /* Adjust color as needed */
    outline: none;
}

.transcription-item {
    background: #1a1a1a; /* Dark grey background */
    color: #e0e0e0; /* Light grey text */
    border-left: 5px solid #007bff; /* Blue accent border */
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2em;
}
.transcription-item:hover {
    background-color: #333333; /* Slightly lighter background */
    transform: translateY(-4px);
    box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.35);
}


.transcription-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Space between header and summary */
}

.transcription-item-fileName-datetime {
    font-size: 1.2em;
    color: #87CEEB;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.transcription-item-fileName-datetime span {
    margin-right: 5px; /* Space between filename and date */
}



.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


.modal {
    background: #1a1a1a; /* Dark grey background */
    color: #e0e0e0; /* Light grey text */
    padding: 20px;
    border-radius: 10px;
    border-left: 5px solid #007bff; /* Blue accent border */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9em;
    display: flex; /* Enable flex layout */
    flex-direction: row; /* Stack children vertically */
    align-items: flex-start; /* Stretch children to fit the width */
    justify-content: space-between; /* Align children to the start */
    width: 80%; /* Adjust the width as needed */
    max-width: 800px; /* Maximum width */
    max-height: 90vh; /* Maximum height to avoid vertical bleeding */
    overflow-y: hidden; /* Enable vertical scrolling */
    z-index: 1001;

}
.modal-content {
    display: flex;
    flex-direction: row; /* Arrange children in a row */
    align-items: flex-start; /* Align children to the start */
    justify-content: space-between; /* Distribute space between columns */
    width: 100%; /* Ensure it fills the modal */
}

.left-section {
    flex: 1 1 60%; /* grow | shrink | basis */
    overflow-y: auto; /* Scroll for overflow content */
    max-height: 90vh; /* Adjust as needed */
    padding: 10px;
    box-sizing: border-box;

}

.right-section {
    flex: 1 1 40%; /* grow | shrink | basis */
    overflow-y: auto; /* Scroll for overflow content */
    max-height: 90vh; /* Adjust as needed */
    padding: 10px;
    box-sizing: border-box;
}

.transcription-item button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.0em;
}
.transcription-summary {

    background-color: #1a1a1a; /* Consistent with modal background */
    border: 1px solid #ccc;
    padding: 10px;
    max-height: 200px; /* Adjust as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    max-width: 350px; /* Maximum width */

}

.transcription-item-summary {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Show only 2 lines */
    -webkit-box-orient: vertical;
    cursor: pointer; /* To indicate it's clickable */
    margin-bottom: 10px; /* Add some spacing */
    transition: transform 0.3s ease; /* Transition for transform property */
}

.transcription-item-summary p {
    margin: 0;
}

.transcription-item-summary.expanded {
    display: block; /* Change display to show full text */
    overflow: visible; /* Show all content */
    -webkit-line-clamp: unset; /* Unset line clamp */
}
.transcription-item-summary:hover {
    font-weight: bold; /* Make text bold on hover */
    transform: scale(1.02); /* Scale up the text slightly */
}
.scrollable-content {
    max-height: 200px; /* Adjust as needed */
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #1a1a1a; /* Match the modal background for consistency */
    margin-bottom: 20px;
    max-width: 350px; /* Maximum width */
}

.chat-area {
    background-color: #2b2b2b;
    border: 1px solid #ccc;
    padding: 10px;
    height: 50vh; /* Fixed height for chat area */
    overflow-y: auto; /* Scroll for overflow content */
    margin-bottom: 10px;
}
.chat-history {
    max-height: 40vh; /* Slightly less than chat-area to allow for input */
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #1a1a1a;
    width: 100%;
    overflow-y: auto; /* Scroll for overflow content */
    height: auto; /* Fixed height for chat area */

}

.chat-message {
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.chat-message.user {
    background-color: #1a1a1a;
}

.chat-message.bot {
    background-color: #2b2b2b;
}
.chat-message-content {
    white-space: pre-wrap;
  }  
#chatInput {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    resize: none;
}
#groupChatInput{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    resize: none;

}

@keyframes ellipsis {
    0% { content: '.'; }
    33% { content: '..'; }
    66% { content: '...'; }
    100% { content: '.'; }
  }

  .ellipsis {
    margin-left: 5px;
    height: 1em;
    width: 2em;
    text-align: left;
    font-size: 40px;
  }
  
  .ellipsis:before {
    content: '.';
    animation: ellipsis 1.5s infinite;
  }
.qButton button{
    padding: 5px 10px; /* Smaller padding for a more compact button */
    background-color: #0f72dd8e; /* A different shade of blue */
    color: white;
    border: 1px solid #0056b3; /* Slightly darker border for some depth */
    border-radius: 3px; /* Smaller radius for a less rounded button */
    cursor: pointer;
    align-self: center; /* Keeps button centered in its container */
    margin: 5px; /* Adds a little space around the button */
    font-size: 0.8em; /* Smaller font size if you want the text to be smaller */
    transition: background-color 0.3s ease; /* Smooth background color transition on hover */

}
  
.common-question-button {
    padding: 5px 10px; /* Smaller padding for a more compact button */
    background-color: #0f72dd8e; /* A different shade of blue */
    color: white;
    border: 1px solid #0056b3; /* Slightly darker border for some depth */
    border-radius: 3px; /* Smaller radius for a less rounded button */
    cursor: pointer;
    align-self: center; /* Keeps button centered in its container */
    margin: 5px; /* Adds a little space around the button */
    font-size: 0.8em; /* Smaller font size if you want the text to be smaller */
    transition: background-color 0.3s ease; /* Smooth background color transition on hover */
}

.common-question-button:hover {
    background-color: #0056b3; /* Darker shade on hover for an interactive effect */
    color: #ffffff;
} 
  .common-question {
    padding: 0.4rem 0.6rem; /* Slightly reduced padding for less bulk */
    border-bottom: 1px solid #3a3a3a; /* Dark line for separation */
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease; /* Transition for color and a subtle lift effect */
    display: block; /* Ensure the entire area is clickable */
    text-decoration: none; /* Remove underline from links if any */
    color: inherit; /* The color will be the same as the container for consistency */
  }  
  .common-question:last-child {
    border-bottom: none; /* Remove bottom border for the last item */
  }
  
  .common-question:hover {
    color: #ffffff; /* Brighter color on hover for contrast */
    background-color: #333; /* Slightly different background on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
  }
  
  /* Optional: Refined style for a title within the container */
  .common-questions-title {
    font-size: 1.1em; /* Slightly smaller font size */
    margin-bottom: 0.6rem; /* Adjusted bottom margin */
    font-weight: 600; /* Slightly bolder for emphasis */
    color: #87CEFA; /* A softer blue for the title */
  }

  .multi-chat-button {
    padding: 10px 20px;
    background-color: #007bff; /* Use your theme color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* Add more styling as needed */
  }
  
  /*Billing modal related code*/
  .billing-header, .billing-row {
    display: flex;
    justify-content: space-between;
  }
  
  .billing-column {
    flex: 1;
    text-align: center;
    padding: 10px 5px; /* Adjust the right and left padding */
    margin-right: 15px; /* Adjust this value if needed */

  }
  
  .billing-header {
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 2px solid #ccc;
    margin-right: 20px; /* Adjust this value if needed */
    margin-left: -30px; /* Adjust this value if needed */
  }
    

  /* Ensure that the modal does not become too wide on large screens */
@media screen and (min-width: 1024px) {
    .modal {
        max-width: 700px; /* Keeps the original max-width */
        flex-direction: column; /* Stack sections vertically */
    }
    .modal-content, .left-section, .right-section {
        max-width: none; /* Remove max-width */
        flex-basis: auto; /* Reset basis for vertical stacking */
    }
    .chat-area {
        height: auto; /* Allow chat area to fit content up to a max height */
        max-height: 40vh; /* Adjust as needed */    }

    .chat-history {
        width: 100%;
        max-width: 1000px; /* Limiting the maximum width */
    }
    .user-credits-container h1{
        padding: 1.5rem; /* Increased padding */
        font-size: 100%; /* Reduced text size */
    }
    .user-credits-container,
    .upload-controls{
        margin-bottom: 3rem; /* Slightly larger margin */
        padding: 1.5rem; /* Increased padding */
        border-radius: 10px; /* Rounded corners */
        font-size: 100%;
    }
}

@media screen and (max-width: 800px) {
    .modal {
        max-width: 500px; /* Keeps the original max-width */
        flex-direction: column; /* Stack sections vertically */
    }
    .modal-content, .left-section, .right-section {
        max-width: none; /* Remove max-width */
        flex-basis: auto; /* Reset basis for vertical stacking */
    }
    .transcription-dashboard h2 {
        font-size: 36px;
        line-height: 48px;
    }

    .transcription-dashboard button,
    .transcription-dashboard input[type='file'] {
        font-size: 16px;
        line-height: 24px;
    }
    .transcription-item {
        flex-direction: column;
        align-items: flex-start;
    }
    .user-credits-container h1{
        padding: 0.8rem; /* Smaller padding */
        font-size: 75%; /* Reduced text size */
    }
    .user-credits-container,
    .upload-controls{
        margin-bottom: 3rem; /* Slightly larger margin */
        padding: 1rem; /* Increased padding */
        border-radius: 7px; /* Rounded corners */
        font-size: 75%;

    }
}

@media screen and (max-width: 490px) {
    .modal {
        padding: 10px; /* Adjust padding */
        max-width: 400px; /* Keeps the original max-width */
        flex-direction: column; /* Stack sections vertically */
        font-size: 0.7em;
    }
    .modal-content, .left-section, .right-section {
        max-width: none; /* Remove max-width */
        flex-basis: auto; /* Reset basis for vertical stacking */
        font-size: 0.7em;
    }
    .transcription-item {
        font-size: 0.7em;
    }
    .common-questions ul{
        font-size: 0.7em;

    }

    .transcription-dashboard h2 {
        font-size: 0.7em;
        line-height: 40px;
    }

    .transcription-dashboard button,
    .transcription-dashboard input[type='file'] {
        font-size: 0.7em;
        line-height: 20px;
    }

    .transcription-item {
        flex-direction: column;
        align-items: flex-start;
    }
    .chat-area {
        /* Adjust chat area to fit content */
        height: auto;
        max-height: 40vh;
    }

    .user-credits-container h1{
        padding: 1.0rem; /* Even smaller padding */
        font-size: 65%; /* Reduced text size */
    }
    .user-credits-container,
    .upload-controls{
        margin-bottom: 3rem; /* Slightly larger margin */
        padding: 0.5rem; /* Increased padding */
        border-radius: 7px; /* Rounded corners */
        font-size: 60%;

    }
}
