/* BillingModal styles - Professional Look */

/* General Styles */
.billingModal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; /* Add right and bottom to ensure the backdrop covers the entire viewport */
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 37, 41, 0.8);
    display: flex;
    justify-content: center; /* Centers horizontally in the flex container */
    align-items: center; /* Centers vertically in the flex container */
    z-index: 1050;
}

.billingModal-content {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 10px;
    max-height: 80vh;
    overflow-y: auto;
    width: 100%; /* Adjust width as needed */
    max-width: 500px; /* Set a maximum width */
    min-width: 400px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    border: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}



.billingModal-title {
    font-weight: 600;
    color: #333;
    margin: 0;
    margin-left: -10px; /* Moves the element 10px to the left */
}

/* Modal Header Styles */
.billingModal-header {
    position: relative; /* Make it a positioning context for the close button */
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaeaea; /* Light border for subtle separation */
    padding-bottom: 15px;
    margin-bottom: 20px;
}

/* Close button specific styling */
.billingModal-close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px; /* Adjust distance from the top */
    right: -50px; /* Adjust distance from the right */
    color: rgb(11, 19, 246); /* Adjusted to match toggle button color */
    border: none;
    border-radius: 10%;
    width: clamp(10px,4vw,24px);
    cursor: pointer;
    margin-right: 40px;
    transition: box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transitions for shadow and background color */
    background-color: #f0f0f0; /* Lighter background for light mode */
}
.billingButtonTray{
    padding-right: 20px;
}

.billingModal-close img {
    width: clamp(10px,4vw,24px);
}
.billingModal-close:hover {
    box-shadow: 0 0 15px #007bff, 0 0 5px #007bff, 0 0 7px #007bff; /* Glowing effect */
    background-color: #68a2ff; /* Darker background color on hover */
}

.dark-mode .billingModal-close {
    background-color: #0787ff; /* Darker background for better contrast in dark mode */
    color: #fff; /* Lighter text color for contrast */
}

.dark-mode .billingModal-close:hover {
    background-color: #ff9500; /* A darker shade of amber for interaction in dark mode */
    box-shadow: 0 0 15px #ff9500, 0 0 5px #ff9500, 0 0 7px #ff9500; /* Adjusted glowing effect for dark mode */
}

/* Table Styles */
.billingModal-table {
    width: 100%;
    border-collapse: collapse; /* Collapsed border for cleaner look */
    font-size: 0.95rem; /* Adjust font size for table */
    table-layout: fixed; /* Fixed table layout for even columns */
}

.billingModal-table th, .billingModal-table td {
    text-align: left;
    padding: 12px 15px; /* Balanced padding for table cells */
    border-bottom: 1px solid #eaeaea; /* Light border for separation */
    vertical-align: middle; /* Center content vertically */
}

.billingModal-table th {
    font-weight: 600; /* Bold headers */
    background-color: #f8f9fa; /* Slightly off-white for headers */
    color: #333; /* Dark text for headers */
}

.billingModal-table td {
    color: #555; /* Slightly lighter text for table content */
}

/* Scrollable Container */
.billingModal-table-container {
    overflow-x: auto; /* Allows table to be scrollable horizontally */
}

/* Buttons */
button.billingModal-button {
    padding: 6px 12px; /* Reduced padding */
    font-size: 0.9rem; /* Smaller font size */
    border-radius: 2px; /* Smaller border radius */
    font-weight: 400; /* Normal font weight */
    text-transform: uppercase; /* Capitalize text */
    letter-spacing: 0.05em; /* Spacing between letters */
    background: #007bff; /* Primary color */
    color: #ffffff; /* Text color */
    border: 1px solid #007bff; /* Border color same as background */
    box-shadow: 0 2px 2px 0 rgba(0, 123, 255, 0.2); /* Subtle shadow for depth */
    transition: all 0.2s ease-in-out; /* Transition for smooth effects */
}

button.billingModal-button:hover {
    background: #0056b3; /* Darker shade on hover */
    box-shadow: 0 2px 4px 0 rgba(0, 123, 255, 0.3); /* Slightly larger shadow on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
}

button.billingModal-button:active {
    background: #003865; /* Even darker for click effect */
    box-shadow: 0 1px 1px 0 rgba(0, 123, 255, 0.3); /* Smaller shadow for pressed effect */
    transform: translateY(1px); /* Reset position on click */
}

/* Close button specific styling */
.billingModal-close {
    background: transparent; /* No background for a cleaner look */
    color: #606060; /* Neutral color */
    font-size: 1.2rem; /* Adjust size as needed */
    transition: color 0.2s ease-in-out; /* Smooth transition for color change */
}

.billingModal-close:hover {
    color: #404040; /* Darker shade on hover */
}

/* Dark Mode - Enhanced Contrast */
.dark-mode .billingModal-content {
    background-color: #1e1e1e; /* Darker background for dark mode */
    color: #d1d1d1; /* Light text for dark mode */
}

.dark-mode .billingModal-title {
    color: #ffffff;
}

/* Enhanced table header styles for Dark Mode */
.dark-mode .billingModal-table th {
    background-color: #343a40; /* Dark background for better contrast */
    color: #ffc107; /* Bright text color for legibility */
    border-bottom: 2px solid #ffc107; /* Brighter border for visibility */
}

/* Optional: Enhance the legibility of table data as well */
.dark-mode .billingModal-table td {
    color: #f8f9fa; /* Lighter text color for better readability */
}

.dark-mode .billingModal-table {
    border-collapse: separate;
    border-spacing: 0 4px; /* Adds spacing between rows for clarity */
}


.dark-mode .billingModal-table th, .dark-mode .billingModal-table td {
    border-bottom: 1px solid #3a3a3a;
}

/* Adjust button colors for dark mode */
.dark-mode button.billingModal-button {
    background: #ffc107;
    color: #1e1e1e;
}

.dark-mode button.billingModal-button:hover {
    background: #e0a800;
}
