
.navbar ul li a {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
}

.nav-signup-card,
.nav-login-card {
    position: fixed !important;
    right: 12px;
    top: 7.4rem;
    width: 300px;
    min-height: 317px;
    border-radius: 15px !important;
    box-shadow: 0px 5px 20px 0px #31004F14;
   
}
.dark-mode .nav-signup-card,
.dark-mode .nav-login-card {
    background-color: #333; /* Dark background color */
    color: #fff; /* Light text color */
}


.popup {
    display: none;
    transition: 1s ease-in-out;
    background-color: #fff; /* Ensure background color is inherited */
}
.dark-mode .popup {
    background-color: #333; /* Darker background color for dark mode */
}

.popup.show {
    display: block;
    opacity: 1;
   
}

.nav-login-card form {
    width: 240px;
    margin: auto;
}

.label {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    color: #161616 !important;
}

.nav-login-card input,
.nav-signup-card input,
.nav-signup-card select{
    border-radius: 20px;
}

.nav-signup-card form {
    width: 260px;
    margin: auto;
}

.sticky-top {
    position: sticky !important;
    top: 0;
    width: 100%;
    z-index: 100;
}