.promotion-text2 {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 20px; /* Adjust font size as needed */
    color: white;
    z-index: 2;
    padding: 10px; /* Add padding for better readability */
    max-width: 300px; /* Limit the width of the text to prevent it from becoming too wide */
    line-height: 1.4; /* Set line height for better readability */
    margin-bottom: 80px;

  }
  
  
  .frame {
    position: fixed; /* Change to fixed */
    bottom: 0; /* Align to the bottom of the viewport */
    right: 0; /* Align to the right of the viewport */
  }
  
  
  .ball {
    position: relative;
    width: 100px;
    height: 100px;
    background: #0d3db8;
    border-radius: 50%;
    filter: blur(15px);
    margin-right: 50px;
    margin-bottom: 80px;
  }
  
  .blubb-1 {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    transform: rotate(30deg); /* Example rotation */
    margin-right: 50px;
    margin-bottom: 80px;
  }
  
  .blubb-1:after {
    position: absolute;
    display: block;
    content: '';
    width: 50px;
    height: 50px;
    background: #1ef0f7;
    border-radius: 50%;
    transform-origin: 20px 20px;
    animation: rotate 3s ease-in-out infinite; /* Example animation */
    filter: blur(5px);
    margin-right: 50px;
    margin-bottom: 80px;
  }
  
  /* Repeat for blubb-2 to blubb-8 */
  
  .sparkle-1 {
    position: absolute;
    top: 38px;
    left: 38px;
    width: 8px;
    height: 8px;
    transform: rotate(45deg); /* Example rotation */
  }
  
  .sparkle-1:after {
    position: absolute;
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    background: #36ffe1;
    border-radius: 50%;
    transform-origin: 38px 38px;
    animation: rotate 3s ease-in-out infinite; /* Example animation */
    filter: blur(3px);
  }
  
  /* Repeat for sparkle-2 to sparkle-10 */
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  