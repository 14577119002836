/* UserProfileModal.css */
.userProfileModal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.userProfileModal-content {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  transform: scale(0.8);
  animation: scaleUp 0.3s forwards;
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: relative;
  color: #333;
}

.userProfileModal-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/ai.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.25;
  z-index: -1;
}

@keyframes scaleUp {
  to {
    transform: scale(1);
  }
}

.userProfileModal-content h2 {
  margin-top: 0;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.userProfileModal-content p {
  color: #222;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
}

.userProfileModal-content input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.setting-modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.setting-modal-buttons button {
  border: none;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.2s, transform 0.3s ease;
  font-weight: bold;
}

.setting-modal-buttons button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Dark Mode Specific Styles */
.dark-mode .userProfileModal-content {
  background: rgba(40, 40, 40, 0.95);
  color: #ccc;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .userProfileModal-content h2,
.dark-mode .userProfileModal-content p {
  color: #fff;
  text-shadow: none;
}

.dark-mode .userProfileModal-content input {
  background-color: #444;
  border-color: #666;
  color: #fff;
}

.dark-mode .setting-modal-buttons button {
  background-color: #17a2b8;
  color: #000;
}

.dark-mode .setting-modal-buttons button:hover {
  background-color: #138496;
}

.phone-number-field {
  margin-bottom: 10px;
}

.phone-number-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.phone-number-field input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.phone-number-field span {
  font-weight: normal;
}

.dark-mode .phone-number-field input {
  background-color: #444;
  border-color: #666;
  color: #fff;
}