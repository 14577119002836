/* Override styles for Card components and their children */
[class*="Card"],
[class*="CardHeader"],
[class*="CardContent"],
.card,
.card-header,
.card-content,
.metrics-card,
.analytics-card,
.compact-card,
.card-wrapper,
.chart-container {
  background-color: transparent !important;
}

.dark-mode [class*="Card"],
.dark-mode [class*="CardHeader"],
.dark-mode [class*="CardContent"],
.dark-mode .card,
.dark-mode .card-header,
.dark-mode .card-content,
.dark-mode .metrics-card,
.dark-mode .analytics-card,
.dark-mode .compact-card,
.dark-mode .card-wrapper,
.dark-mode .chart-container {
  background-color: transparent !important;
}

/* Adding dark mode styles for Action Items and Tips & Recommendations */
.dark-mode .scrollable-content > div > div {
  background: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dark-mode .scrollable-content > div > div:hover {
  background: #202020;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dark-mode .insights-card {
  
}

.dark-mode .insights-card .card-header {
  
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .scrollable-content > div > div .text-sm {
  color: #e0e0e0;
}

.dark-mode .scrollable-content > div > div .text-xs {
  color: #90A4AE;
}

.dark-mode .sticky.top-0.bg-card {
  background-color: transparent !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
}

/* Main container */
.intelligence-dashboard {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 20px;
  scrollbar-width: thin;
  scrollbar-color: #2196F3 transparent;
  transition: background 0.3s ease;
}

/* Light/Dark Mode Styles */
.intelligence-dashboard.light-mode {
  background: linear-gradient(to bottom right, #f8f9fc, #e3f2fd);
  color: #2c3e50;
}

.intelligence-dashboard.dark-mode {
  background: linear-gradient(to bottom right, #0f0f0f, #181818);
  color: #e0e0e0;
}

/* Dashboard Content Container */
.dashboard-content {
  max-width: 1800px;
  margin: 0 auto;
  width: 100%;
}

/* Grid Container */
.dashboard-content > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
  width: 100%;
}

@media (max-width: 1024px) {
  .dashboard-content > div {
    grid-template-columns: 1fr;
  }
}

/* Heading styles */
.intelligence-dashboard h1 {
  text-align: center;
  margin-bottom: 40px;
  color: inherit;
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px rgba(33, 150, 243, 0.1);
  transition: color 0.3s ease;
}

.welcome-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: bold;
  background: linear-gradient(90deg, #2196F3, #64B5F6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: color 0.3s ease;
}

.dark-mode .welcome-title {
  background: linear-gradient(90deg, #64B5F6, #90CAF9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard-section {
  margin-bottom: 0;
  padding: 20px;
  background-color: var(--section-bg, rgba(255, 255, 255, 0.95));
  border-radius: 8px;
  border: 1px solid rgba(33, 150, 243, 0.2);
  transition: all 0.3s ease;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dark-mode .dashboard-section {
  background-color: rgba(24, 24, 24, 0.95);
  border-color: rgba(255, 255, 255, 0.1);
  color: #e0e0e0;
}

.section-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: inherit;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(33, 150, 243, 0.2);
  transition: color 0.3s ease, border-color 0.3s ease;
}

.dark-mode .section-title {
  border-bottom-color: rgba(255, 255, 255, 0.1);
  color: #e0e0e0;
}

/* Stats Grid */
.stats-grid {
  display: grid;
  font-size: 0.875rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.stat-card {
  display: flex;
  flex-direction: column;
  max-height:150vh;
  padding: 1px;
  border-radius: 8px;
  margin-bottom: 1px;
  box-shadow: 0 4px 6px rgba(33, 150, 243, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.stat-card:nth-child(1) {
  background: linear-gradient(135deg, #E3F2FD, #BBDEFB);
  border-left: 4px solid #2196F3;
}

.stat-card:nth-child(2) {
  background: linear-gradient(135deg, #E8F5E9, #C8E6C9);
  border-left: 4px solid #4CAF50;
}

.stat-card:nth-child(3) {
  background: linear-gradient(135deg, #F3E5F5, #E1BEE7);
  border-left: 4px solid #9C27B0;
}

.stat-card:nth-child(4) {
  background: linear-gradient(135deg, #FFF3E0, #FFE0B2);
  border-left: 4px solid #FF9800;
}

.stat-card .card-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(33, 150, 243, 0.2);
  
}

.stat-card .card-content {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  
}

/* Responsive adjustments for stats grid */
@media (max-width: 1024px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }
}

.dark-mode .stat-card {
  background-color: transparent !important;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.dark-mode .stat-card:nth-child(1) { background: linear-gradient(145deg, #0b4b8a, #2196F3); }
.dark-mode .stat-card:nth-child(2) { background: linear-gradient(145deg, #0c5110, #4CAF50); }
.dark-mode .stat-card:nth-child(3) { background: linear-gradient(145deg, #611383, #9C27B0); }
.dark-mode .stat-card:nth-child(4) { background: linear-gradient(145deg, #a16702, #bf9003); }

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(33, 150, 243, 0.2);
}

.dark-mode .stat-card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
}

/* Card Components */
.card {
  background-color: transparent !important;
  border-radius: 8px;
  border: 1px solid rgba(33, 150, 243, 0.2);
  transition: all 0.3s ease;
  color: #2c3e50;
}

.dark-mode .card {
  border-color: rgba(255, 255, 255, 0.1);
  color: #e0e0e0 !important;
}

.card-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(33, 150, 243, 0.2);
  transition: all 0.3s ease;
  background-color: transparent !important;
  color: #2c3e50;
}

.dark-mode .card-header {
  border-bottom-color: rgba(255, 255, 255, 0.1);
  color: #e0e0e0;
}

.card-content {
  padding: 1rem;
  transition: background-color 0.3s ease;
  background-color: transparent !important;
  color: #2c3e50;
}

.dark-mode .card-content {
  color: #e0e0e0;
}

/* General Card Title Styling */
.card-title {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  white-space: normal;
  line-height: 1.25;
  color: #2c3e50;
}

.dark-mode .card-title {
  color: #e0e0e0;
}

/* Text Wrapping for Titles */
.card-title {
  overflow-wrap: break-word;
}

/* Responsive Text Sizing for Smaller Screens */
@media (max-width: 768px) {
  .card-title {
    font-size: 0.875rem;
  }
}

/* Chart Container */
.chart-container {
  width: 100%;
  height: 85%;
  min-height: 300px;
  margin: 0;
  position: relative;
  border: 1px solid rgba(33, 150, 243, 0.15);
  box-shadow: 0 4px 6px rgba(33, 150, 243, 0.1);
}

/* Chart Wrapper Adjustments */
.chart-wrapper {
  width: 100%;
  height: 100%;
  min-height: 250px;
  margin: 0;
  overflow: hidden;
  position: relative;
}

/* Chart Containers */
.chart-container,
.insights-card,
.analytics-card,
.metrics-card {
  background-color: transparent !important;
  padding: 1px;
  border-radius: 8px;
  margin-bottom: 5px;
  box-shadow: 0 4px 6px rgba(33, 150, 243, 0.1);
  transition: all 0.3s ease;
  color: #2c3e50;
  position: relative;
  overflow: hidden;
}

.dark-mode .chart-container,
.dark-mode .insights-card,
.dark-mode .analytics-card,
.dark-mode .metrics-card {
  background-color: transparent !important;
  color: #e0e0e0;
  border-color: rgba(255, 255, 255, 0.1);
}

/* Ensure chart content stays within bounds */
.recharts-wrapper {
  transition: all 0.3s ease;
  max-width: 100%;
  max-height: 100%;
}

/* Insights Section */
.insights-section {
  height: 600px;
  font-size: 0.875rem;
  overflow-y: auto;
}

.insights-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  
  border: 1px solid rgba(33, 150, 243, 0.15);
  box-shadow: 0 4px 6px rgba(33, 150, 243, 0.1);
}

.insights-card h3 {
  font-size: 1.25rem;
}

.insights-section .grid {
  max-height: none;
}

.dark-mode .insights-section {
  color: #e0e0e0;
}

.dark-mode .insights-card {
  
  border-color: rgba(255, 255, 255, 0.1);
}

.insights-section .grid::-webkit-scrollbar {
  width: 8px;
}

.insights-section .grid::-webkit-scrollbar-track {
  background: transparent;
}

.insights-section .grid::-webkit-scrollbar-thumb {
  background-color: rgba(33, 150, 243, 0.3);
  border-radius: 10px;
  border: 2px solid transparent;
}

.dark-mode .insights-section .grid::-webkit-scrollbar-thumb {
  background-color: rgba(100, 181, 246, 0.3);
}

/* Text Colors */
.text-muted-foreground {
  color: #546E7A;
  transition: color 0.3s ease;
}

.dark-mode .text-muted-foreground {
  color: #90A4AE;
}

/* Fix for Tailwind text colors */
.dark-mode .text-gray-800,
.dark-mode .text-gray-900,
.dark-mode .text-black {
  color: #e0e0e0 !important;
}

.dark-mode .text-gray-600,
.dark-mode .text-gray-700 {
  color: #90A4AE !important;
}

/* Progress Bar */
.progress {
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, 
    rgba(33, 150, 243, 0.1), 
    rgba(33, 150, 243, 0.05)
  );
  border-radius: 4px;
  /* Progress Bar (continued) */
  overflow: hidden;
  transition: all 0.3s ease;
}

.dark-mode .progress {
  background-color: rgba(255, 255, 255, 0.1);
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #2196F3, #64B5F6);
  transition: width 0.6s ease;
}

.dark-mode .progress-bar {
  background: linear-gradient(90deg, #64B5F6, #90CAF9);
}

/* Chart Specific Styles */
.recharts-wrapper {
  transition: all 0.3s ease;
}

.dark-mode .recharts-text {
  fill: #e0e0e0 !important;
}

.dark-mode .recharts-cartesian-grid line {
  stroke: rgba(255, 255, 255, 0.1);
}

.dark-mode .recharts-cartesian-axis-line {
  stroke: rgba(255, 255, 255, 0.2);
}

.recharts-default-tooltip {
  background-color: var(--tooltip-bg, rgba(255, 255, 255, 0.9)) !important;
  border: 1px solid rgba(33, 150, 243, 0.2) !important;
  border-radius: 4px;
  padding: 8px 12px !important;
  transition: all 0.3s ease;
  color: #2c3e50;
}

.dark-mode .recharts-default-tooltip {
  background-color: rgba(24, 24, 24, 0.9) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  color: #e0e0e0;
}

/* Sticky Headers */
.sticky.top-0.bg-card {
  position: sticky;
  top: 0;
  z-index: 10;
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
  background-color: transparent !important;
}

.dark-mode .sticky.top-0.bg-card {
  background-color: transparent !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Action Items and Tips styling */
.scrollable-content > div > div {
  
  border: 1px solid rgba(33, 150, 243, 0.1);
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
  .intelligence-dashboard {
    padding: 10px;
  }

  .intelligence-dashboard h1 {
    font-size: 28px;
  }

  .stats-grid {
    flex-direction: column;
  }

  .stat-card {
    min-width: 100%;
  }

  .analytics-grid {
    grid-template-columns: 1fr;
  }

  .dashboard-section {
    padding: 15px;
  }

  .section-title {
    font-size: 20px;
  }

  .insights-section {
    height: 500px;
  }
}

/* Analytics Section Specific */
.analytics-grid {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

/* Metrics Grid */
.metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 24px;
}

/* Grid for Detailed Analytics */
.grid.grid-cols-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

@media (max-width: 1024px) {
  .grid.grid-cols-3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid.grid-cols-2 {
    grid-template-columns: 1fr;
  }

  .dashboard-content > div {
    grid-template-columns: 1fr;
  }

  .metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .grid.grid-cols-3,
  .metrics-grid {
    grid-template-columns: 1fr;
  }
}

/* Separate Usage Patterns Row */
.mt-6 {
  margin-top: 1.5rem;
}

/* Individual Row for Usage Patterns */
.usage-patterns-card {
  grid-column: 1 / -1;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.metrics-grid .card {
  height: 100%;
  min-height: 160px;
  
}

/* Compact Cards in Analytics */
.compact-card {
  display: flex;
  flex-direction: column;
  height: 95%;
  border: 1px solid rgba(33, 150, 243, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.compact-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(33, 150, 243, 0.15);
}

.dark-mode .compact-card {
  border-color: rgba(255, 255, 255, 0.1);
}

.compact-card .card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  
}

/* Progress Bars in Analytics */
.analytics-card .progress {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/* Card Content Spacing */
.analytics-card .card-content {
  padding: 1rem;
  height: 100%;
  
}

/* Metric Value Alignment */
.analytics-card .text-right {
  width: 100%;
  margin-top: 0.25rem;
}

/* Detailed Analytics Section Styling */
.detailed-analytics {
  padding: 20px;
  background-color: white !important;
  border-radius: 8px;
  border: 1px solid rgba(33, 150, 243, 0.2);
  transition: all 0.3s ease;
}

.dark-mode .detailed-analytics {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: transparent !important;
}

/* Usage Patterns Row */
.usage-patterns-row {
  margin-top: 1.5rem;
}

.usage-patterns-row .analytics-card {
  padding-top: -10px;
  border: 1px solid rgba(33, 150, 243, 0.15);
  box-shadow: 0 4px 6px rgba(33, 150, 243, 0.1);
}

.dark-mode .usage-patterns-row .analytics-card {
  border-color: rgba(255, 255, 255, 0.1);
}

/* Metric Title Styling */
.metric-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #2c3e50;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.25;
}

.dark-mode .metric-title {
  color: #e0e0e0;
}

/* Metric Title Icons */
.metric-title svg {
  width: 16px;
  height: 16px;
  color: #2196F3;
}

.dark-mode .metric-title svg {
  color: #64B5F6;
}

/* Styling for Metric Values */
.metric-value {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary, #2c3e50);
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dark-mode .metric-value {
  color: #e0e0e0;
}

/* Metric Description Styling */
.metric-description {
  font-size: 0.875rem;
  color: var(--text-muted, #546E7A);
}

.dark-mode .metric-description {
  color: #90A4AE;
}

/* Scrollable Content */
.scrollable-content {
  max-height: 200px;
  max-width: fit-content;
  overflow-y: auto;
  padding-right: 10px;
}

/* Scrollbar Styling */
.scrollable-content::-webkit-scrollbar {
  width: 6px;
}

.scrollable-content::-webkit-scrollbar-track {
  
  border-radius: 3px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  
  border-radius: 3px;
  transition: background 0.3s ease;
}

.dark-mode .scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(100, 181, 246, 0.3);
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(33, 150, 243, 0.5);
}

/* Prevent Duplicate Bullets */
.scrollable-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.scrollable-content li {
  list-style-position: outside;
}

.text-muted-foreground.italic {
  opacity: 0.8;
  line-height: 1.2;
}

.compact-card .text-muted-foreground.italic {
  font-size: 10px;
  margin-top: 4px;
}