/* ############################## Help Content Styling ############################## */

/* Main container for help content */
.helpContent-body {
    padding: 20px;
    color: inherit; /* Inherit text color based on the theme */
  }
  
  /* Headings styling */
  .helpContent-body h2,
  .helpContent-body h3,
  .helpContent-body h4 {
    color: #007bff; /* Default color for headings */
    margin-top: 20px;
  }
  
  /* Adjust heading colors for dark mode */
  .dark-mode .helpContent-body h2,
  .dark-mode .helpContent-body h3,
  .dark-mode .helpContent-body h4 {
    color: #ffc107;
  }
  
  /* Paragraph styling */
  .helpContent-body p {
    margin: 10px 0;
    color: inherit;
  }
  
  /* List styling */
  .helpContent-body ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .helpContent-body li {
    margin-bottom: 5px;
    color: inherit;
  }
  
  /* FAQ Specific Styles */
  .helpContent-body .helpContent-faqSection strong {
    color: #007bff; /* Bold blue for questions */
    font-weight: bold;
  }
  
  .dark-mode .helpContent-body .helpContent-faqSection strong {
    color: #ffc107; /* Adjusted color for dark mode */
  }
  
  /* Video Tutorials Section */
  .video-tutorials-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .video-tutorial-button {
    padding: 10px 15px;
    background-color: #f0f0f0;
    color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .video-tutorial-button:hover {
    background-color: #e0e0e0;
  }
  
  .dark-mode .video-tutorial-button {
    background-color: #343a40;
    color: #ffc107;
  }
  
  .dark-mode .video-tutorial-button:hover {
    background-color: #495057;
  }
  
  /* Video Tutorial Container */
  .video-tutorial {
    margin-top: 20px;
  }
  
  .video-tutorial h4 {
    margin-bottom: 10px;
    color: inherit;
  }
  
  .video-tutorial video {
    width: 100%;
    height: auto;
    border: 1px solid #007bff;
    border-radius: 5px;
  }
  
  .dark-mode .video-tutorial video {
    border-color: #ffc107;
  }
  
  /* Adjustments for Links */
  .helpContent-body a {
    color: #007bff;
    text-decoration: underline;
  }
  
  .dark-mode .helpContent-body a {
    color: #ffc107;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .video-tutorials-list {
      flex-direction: column;
    }
  }
  
  /* Additional Styles as Needed */
  /* You can add more styles here to match your application's theme */
  