/* ################################# Help Section Modal ################################# */
/* Light Mode - Bold and Vibrant */
.helpModal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(108, 117, 125, 0.6); /* Darker backdrop for more contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
  
  .helpModal-content {
    background-color: #ffffff; /* Bright white to contrast the dark backdrop */
    padding: 20px;
    border-radius: 8px; /* Slightly larger radius for a modern look */
    max-height: 80vh;
    overflow-y: auto;
    width: 90%; /* Wider for a bold statement */
    box-shadow: 0 5px 15px rgba(155, 180, 206, 0.5); /* Vibrant blue shadow for a bold look */
    border: 1px solid #007bff; /* Bright blue border */
  }
  
  .helpModal-close {
    float: right;
    border: none;
    background: none;
    font-size: larger;
    cursor: pointer;
    color: #007bff; /* Matching the border for consistency */
  }
  
  .helpModal-body h2, .helpModal-body h3 {
    color: #007bff; /* Bold blue for headings */
  }
  
  .helpModal-body p {
    margin: 10px 0;
    color: #343a40; /* Darker text for contrast and readability */
  }
  
  /* FAQ Specific Styles for Light Mode */
  .helpModal-faqSection strong {
    color: #007bff; /* Bold blue for questions to match headings */
    font-weight: normal; /* Optional: adjust font weight as needed */
  }
  
  .helpModal-faqSection p {
    color: #343a40; /* Darker text for contrast and readability, applied globally */
  }
  
  .helpModal-faqSection p A {
    display: block; /* Ensures answers are clearly distinguished */
    margin-left: 20px; /* Indent answers for clear differentiation */
    color: #495057; /* Slightly lighter than general text for distinction */
  }
  
  /* Dark Mode - Bold and Vibrant */
  .dark-mode .helpModal-backdrop {
    background-color: rgba(33, 37, 41, 0.75); /* Even darker for bold contrast */
  }
  
  .dark-mode .helpModal-content {
    background-color: #212529; /* Very dark grey for a sleek look */
    color: #f8f9fa; /* Soft white for readability */
    box-shadow: 0 5px 15px rgba(255, 193, 7, 0.5); /* Bold yellow shadow for a unique look */
    border: 1px solid #ffc107; /* Bright yellow border */
  }
  
  .dark-mode .helpModal-body p {
    color: #e9ecef; /* Lighter grey for readability against the dark background */
  }

  button.helpModal-button {
    background: none;
    border: none;
    outline: none; /* Add this to remove any default browser outline */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

  button.helpModal-button:hover {
    /* Example hover effect: scale transform */
    transform: scale(1.1);
    /* Add other hover effects as needed */
  }

  button.helpModal-button img {
    border: none; /* Ensure no border */
    display: block; /* To prevent any unexpected margins */
    width: auto; /* Adjust based on your design */
    height: auto; /* Adjust based on your design */
    transition: filter 0.3s ease; /* Smooth transition for the filter */

}

button.helpModal-button:hover img {
    transform: scale(1.1); /* Example hover effect */
}



/* Assuming .dark-mode is applied to the body or a high-level parent element */
body.dark-mode .helpModal-backdrop .helpModal-content .helpModal-body h3 {
    color: #ffc107;
}

  
.nav-item img {
  width: 24px; /* Ensure icons have a consistent size */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transitions */
}

.nav-link:hover img {
  transform: scale(1.1); /* Slightly enlarge icons on hover */
  filter: brightness(120%); /* Increase brightness on hover */
}

/* Dropdown Menu */
.dropdown-menu {
  display: block;
  position: absolute;
  margin-top: 5px;
  background-color: #f0f0f0; /* Lighter background for light mode */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 5px 0; /* Padding around the items */
}

.dropdown-item {
  display: block;
  padding: 10px 20px;
  color: rgb(11, 19, 246); /* Matching the toggle button text color */
  text-decoration: none;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #68a2ff; /* Darker background color on hover */
}

/* Dark Mode Adaptations */
.dark-mode .nav-item img {
  filter: invert(1); /* Invert colors for dark mode for better visibility */
  border: none;
}

.dark-mode .nav-link:hover img {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5); /* Distinct shadow for dark mode */
  filter: brightness(80%); /* Adjust brightness in dark mode */
}

.dark-mode .dropdown-menu {
  background-color: #0787ff; /* Darker background for better contrast in dark mode */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjusted shadow for darker theme */
}

.dark-mode .dropdown-item {
  color: #fff; /* Lighter text color for better contrast in dark mode */
}

.dark-mode .dropdown-item:hover {
  background-color: #ff9500; /* A darker shade of amber for interaction in dark mode */
  color: #000; /* Optional: Change text color on hover for contrast */
}