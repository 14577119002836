/* Slider */
.testimonials .slick-slider {
  margin: 30px auto;
  position: relative;
}

/* Slides */
.testimonials .slick-slide {
  transition: all 0.5s ease;
}

/* Inner item within each slide */
.testimonials .testimonial-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.testimonials .testimonial-item:hover {
  transform: translateY(-5px);
}

.testimonials .testimonial-item img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

.testimonials .testimonial-item p {
  font-size: 18px;
  margin-bottom: 10px;
}

.testimonials .testimonial-item .testimonial-name {
  font-size: 16px;
  font-weight: bold;
}

/* Dots */
.testimonials .slick-dots {
  bottom: -45px;
}

.testimonials .slick-dots li {
  margin: 0 5px;
}

.testimonials .slick-dots li button {
  font-size: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  padding: 0;
  transition: background-color 0.3s ease;
}

/* Arrows */
.testimonials .slick-prev,
.testimonials .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  background: transparent;
  z-index: 1;
}

.testimonials .slick-prev:before,
.testimonials .slick-next:before {
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
}

.testimonials .slick-prev {
  left: -45px;
}

.testimonials .slick-next {
  right: -45px;
}

.testimonials .slick-prev:before {
  content: "←";
  color: #000; /* Change the color to black for better visibility in light mode */
}

.testimonials .slick-next:before {
  content: "→";
  color: #000; /* Change the color to black for better visibility in light mode */
}

/* Active and Focus states */
.testimonials .slick-slide.slick-active,
.testimonials .slick-slide:focus {
  outline: none;
}

/* Ensure the slick-track is always aligned in the center */
.testimonials .slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ensure each slick-list has a minimum height to prevent collapsing */
.testimonials .slick-list {
  min-height: 150px;
}

/* Light Mode */
.testimonials .light-mode .slick-slider {
  background-color: #f8f9fa;
}

.testimonials .light-mode .testimonial-item {
  background-color: #ffffff;
  color: #212529;
}

.testimonials .light-mode .testimonial-item img {
  border: 4px solid #ffffff;
}

.testimonials .light-mode .slick-dots li button {
  background-color: #ced4da;
}

.testimonials .light-mode .slick-dots li.slick-active button {
  background-color: #495057;
}

/* Dark Mode */
/* Arrows */
.dark-mode .testimonials .slick-prev,
.dark-mode .testimonials .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  background: transparent;
  z-index: 1;
}

.dark-mode .testimonials .slick-prev:before,
.dark-mode .testimonials .slick-next:before {
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
}

.dark-mode .testimonials .slick-prev {
  left: -45px;
}

.dark-mode .testimonials .slick-next {
  right: -45px;
}

.dark-mode .testimonials .slick-prev:before {
  content: "←";
  color: #f8f9fa; /* Change the color to black for better visibility in light mode */
}

.dark-mode .testimonials .slick-next:before {
  content: "→";
  color: #f8f9fa; /* Change the color to black for better visibility in light mode */
}

.dark-mode .shadow-effect p{
  color: #000;
}