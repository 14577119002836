.promotion-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 20px 0;
  padding-bottom: 50px;
}

.promotion-text {
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(16px, 2.5vw, 26px);
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.btn-container {
  position: relative;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15em;
  height: 2.5em;
  font-size: clamp(10px, 2.5vw, 20px);
  text-align: center;
  border-radius: 1.25em;
  background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5);
  background-size: 600%;
  animation: anime 16s linear infinite;
}

.btn2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(30px);
  opacity: 0.8;
  border-radius: 1.25em;
}

@keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Light mode styles */
.light-mode .btn,
.light-mode .btn2 {
  color: rgba(255, 255, 255, 0.9);
}

.light-mode .btn2 {
  background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5);
}

/* Dark mode styles */
.dark-mode .btn,
.dark-mode .btn2 {
  color: rgba(255, 255, 255, 0.9);
}

.dark-mode .btn2 {
  background: linear-gradient(-45deg, rgba(255, 166, 61, 0.5), rgba(255, 61, 119, 0.5), rgba(51, 138, 255, 0.5), rgba(60, 240, 197, 0.5));
}