
body {
    background: #04db61;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  #wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }
  
  #wrap canvas {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 500px;
    height: 500px;
    margin: -250px 0 0 -250px;
    outline: 1px solid #fff;
  }
  
  .info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 12px;
    color: #10f47a;
    line-height: 2em;
    text-align: center;
  }
  .progress-bar-container {
    position: relative;
    width: 100%;
    height: 20px;
    margin: 10px 0;
    overflow: hidden;
  }
  
  .progress-bar-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
  }
  
  .progress-bar-wrapper canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: 1px solid #fff;
  }

  .status-text {
    font-family: 'Orbitron', sans-serif;
    font-size: 14px;
    margin-top: 8px;
    text-align: center;
    text-transform: uppercase;
    z-index: 1020;
  }
  
  .status-text.light-mode {
    color: #4a4a4a;
  }
  
  .status-text.dark-mode {
    color: #f0f0f0;
  }

  .status-text {
    font-family: 'Orbitron', sans-serif;
    font-size: 14px;
    margin-top: 8px;
    text-align: center;
    text-transform: uppercase;
    z-index: 1020;
  }
  
  .status-text.light-mode {
    color: #4a4a4a;
  }
  
  .status-text.dark-mode {
    color: #f0f0f0;
  }