
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  /* Colors */
  --blue: #3A80EC;
  --white: #FFF;
  --white2: #D8D5D1CC;
  --black: #000;
  --black1: #161616;
  --black2: #434343CC;
  --black3: #231E26;
  --black4: #5A4C62;
  --gray: #F5F5F5;
  --gray1: #DFDFDF;
  --dark-blue: #0A1423;
  --dark-blue1: #12284A;
  --linear-g-blue: linear-gradient(0deg, rgba(40, 120, 240, 0.3), rgba(40, 121, 243, 0.3));
  --gradient-1: linear-gradient(135deg, #6E57E0, #FF8AC5);
  --soft-edge-color: rgba(255,255,255,0.7);
  --hover-effect-shadow: rgba(0, 0, 0, 0.2);
  --dark-mode-blue: #12284A; /* Adjust this to the blue you want in dark mode */
  --light-mode-blue: #3A80EC; /* Your existing blue color for light mode */
  --caption: #434343CC;
}

.navbar {
  /* White shadow with blur */
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  /* max-width: 1140px !important; */
  margin: auto;
  border-radius: 15px !important;

  /* Fallback for browsers not supporting backdrop-filter */
  z-index: 200 !important;
}
.caption{
  color: var(--caption);
}

.nav-login-card, .nav-signup-card {
  z-index: 1050; /* Adjust the value to be higher than other elements */
  
}
.nav-login-card, .nav-signup-card .dark-mode {
  z-index: 1050; /* Adjust the value to be higher than other elements */
  
}
/* ################################### User Profile Modal ################################### */

/* Backdrop styling */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it's above most other content */
}

/* Modal content styling */
.modal-content {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px; /* Adjust based on your preference */
}

/* Header style */
.modal-content h2 {
  margin-top: 0;
  color: #333;
}

/* Paragraph style for user details */
.modal-content p {
  color: #666;
  line-height: 1.5;
}

/* Button styling */
.modal-content button {
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  float: right; /* Align the button to the right */
}

.modal-content button:hover {
  background-color: #0056b3; /* Darken button on hover */
}




/* ################################### Animations ################################### */
.gradient__text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes sparkle {
  0%, 20%, 40%, 60%, 80%, 100% {
    opacity: 0;
  }
  10%, 30%, 50%, 70%, 90% {
    opacity: 1;
  }
}

.sparkle {
  animation: sparkle 20s infinite ease-in-out;
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

/*Top right*/
.pulse {
  animation: pulse 3s infinite;
  animation-delay: 3s; /* Starts after the first animation */
}

/*Top Left*/
.pulse2 {
  animation: pulse 3s infinite;
  animation-delay: 1.5s; /* Starts after the first animation */
}

/*bottom right*/
.pulse3 {
  animation: pulse 3s infinite;
  animation-delay: 4.5s; /* Starts after the second animation */
}

/*bottom left*/
.pulse4 {
  animation: pulse 3s infinite;
  animation-delay: 6s; /* Starts after the third animation */
}

@keyframes twinkle {
  40%, 80% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.0);
  }
}

.twinkle {
  animation: twinkle 1.5s infinite alternate;
}

@keyframes glimmer {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.glimmer {
  animation: glimmer 4s infinite ease-in-out;
}

@keyframes waveHand {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
}

.waving-hand {
  animation: waveHand 1.5s ease-in-out infinite;
  transform-origin: bottom center; /* You may need to adjust this depending on your SVG */
}

@keyframes shimmer {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.shimmering-spots {
  animation: shimmer 2s infinite;
}

@keyframes searchPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}
.gradient-effect {
  animation: gradientShift 3s infinite alternate;
  background: linear-gradient(45deg, #007bff, #ff47ab);
  background-size: 200% 200%;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}


.searchPulse {
  animation: pulse 2s infinite;
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.logo-animate {
  animation: pulseAnimation 2s infinite ease-in-out !important;
}

.logo-container:hover {
  filter: drop-shadow(0 0 10px #00e5ff) drop-shadow(0 0 20px #0400ff); /* Layered shadows for a more pronounced glow */
  transition: filter 0.3s ease; /* Smooth transition for the glow effect */
}


/* ################################### User Info ################################### */
.animated-infinite {
  animation: rotateScaleAnimation 1s infinite alternate; /* Adjust timing and animation name as needed */
}

@keyframes rotateScaleAnimation {
  0%, 100% {
      opacity: 0.8;
      transform: rotate(-10deg) scale(0.9);
  }
  50% {
      opacity: 1;
      transform: rotate(10deg) scale(1.1);
  }
}

.status-text {
  font-family: 'Orbitron', sans-serif;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
  text-transform: uppercase;
  z-index: 1020;
}

.status-text.light-mode {
  color: #4a4a4a;
}

.status-text.dark-mode {
  color: #f0f0f0;
}

.notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9); /* Adjust the opacity as needed */
  padding: 25px;
  border-radius: 10px;
  max-height: 80vh;
  overflow-y: auto;
  width: 100%;
  max-width: 500px;
  min-width: 400px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px); /* Apply the blurry background effect */
  border: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index:1050;
}

.notification p {
  margin-bottom: 20px;
}

.notification button {
  padding: 6px 12px;
  font-size: 0.9rem;
  border-radius: 2px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background: #007bff;
  color: #ffffff;
  border: 1px solid #007bff;
  box-shadow: 0 2px 2px 0 rgba(0, 123, 255, 0.2);
  transition: all 0.2s ease-in-out;
}

.notification button:hover {
  background: #0056b3;
  box-shadow: 0 2px 4px 0 rgba(0, 123, 255, 0.3);
  transform: translateY(-2px);
}

.notification button:active {
  background: #003865;
  box-shadow: 0 1px 1px 0 rgba(0, 123, 255, 0.3);
  transform: translateY(1px);
}

/* Dark Mode */
.dark-mode .notification {
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 10px 25px rgba(255, 255, 255, 0.2);
  color: #ffffff; /* Adjust text color as needed */
}

.dark-mode .notification button {
  background: #007bff;
  color: #ffffff;
  border: 1px solid #007bff;
  box-shadow: 0 2px 2px 0 rgba(0, 123, 255, 0.2);
}

.dark-mode .notification button:hover {
  background: #0056b3;
  box-shadow: 0 2px 4px 0 rgba(0, 123, 255, 0.3);
}

.dark-mode .notification button:active {
  background: #003865;
  box-shadow: 0 1px 1px 0 rgba(0, 123, 255, 0.3);
}

/* ################################### View Port ################################### */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.exploring-section.in-view {
  animation: fadeInUp 0.5s forwards; /* Adjust duration as needed */
  visibility: visible;
}

.exploring-section {
  visibility: hidden; /* Initially hide the section */
}

/* ################################### Exploring Carousel ################################### */
.trans3d {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

#carouselContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  --radius: 300px; /* Adjust the radius of the circle */
  --total-items: 20; /* Adjust the total number of items */
  --angle: calc(360deg / var(--total-items)); /* Calculate the angle between items */
  position: relative;
  width: 100%;
  height: 600px; /* Adjust based on your requirements and the radius */
  transform-style: preserve-3d;
}

.carouselItem {
  position: absolute;
  width: 140px; /* Adjust width */
  height: 140px; /* Adjust height */  top: calc(50% - 100px); /* Center vertically */
  top: calc(50% - 35px); /* Center vertically */
  left: calc(50% - 35px); /* Center horizontally */  margin: 10px;
  transform-origin: 50% 50%;
  transform: rotate(calc(var(--angle) * var(--i))) translateX(-var(--radius)) rotate(calc(-1 * var(--angle) * var(--i)));
}

.carouselItemInner {
  width: clamp(90px, 3vw, 110px);
  height: clamp(90px, 3vh, 110px);
  background: linear-gradient(45deg, rgba(160, 245, 149, 0.15), rgba(123, 245, 141, 0.15), rgba(88, 245, 168, 0.15), rgba(90, 231, 245, 0.15), rgba(90, 156, 245, 0.15), rgba(122, 90, 245, 0.15), rgba(179, 90, 245, 0.15), rgba(245, 90, 245, 0.15), rgba(245, 141, 205, 0.15), rgba(245, 141, 134, 0.15), rgba(245, 141, 71, 0.15), rgba(245, 168, 88, 0.15));
  background-size: 300% 300%; /* Adjust the size of the gradient */
  animation: gradientAnimation 0.5s infinite alternate; /* Apply animation */
  color: aqua;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 70px;
  border-radius: 20px; /* Add border radius for style */
  backdrop-filter: blur(5px); /* Add blur effect */
  margin: -100px;
}

.carouselItemInner img {
  width: 80px; /* Ensure the image takes up the full width of its container */
  height: 80px; /* Ensure the image takes up the full height of its container */
  object-fit: cover; /* Ensure the image covers its container */
}


@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}



/* ################################### Modal CSS ################################### */
/* Ensure the entire modal overlay covers the viewport */
.modal {
  position: fixed; /* Fixed position to cover the whole screen */
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  display: flex; /* Use flexbox for easy centering */
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1050; /* High z-index to ensure it's on top */
}

.btn-primary {
  margin-right: 10px; /* Adds space to the right of the 'Buy Credits' button */
}

/* Optional: Add a divider between buttons if needed */
.divider {
  border-right: 1px solid #dee2e6; /* Use a color that matches your modal's theme */
  height: 24px; /* Adjust to match the height of the buttons */
  margin-right: 10px; /* Space after the divider */
}

/* Center the modal dialog within the overlay */
.modal-dialog {
  margin: auto; /* Auto margins for additional centering if needed */
}

/* Optional: Animate the modal appearance for a smoother UX */
.modal-content {
  animation: modalFadeIn 0.3s;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px); /* Slightly raised starting position */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Optional: Style adjustments for the close button for better visibility */
.btn-close {
  border: none;
  background: transparent;
  color: #000; /* Adjust based on your modal header's background color */
  font-size: 1.5rem; /* Larger close icon */
  cursor: pointer;
}

/* Hide the modal by default */
.modal.show {
  display: none; /* Hidden when not triggered */
}


/* ################################### HOME PAGE CSS STARTS ################################### */

.home-banner {
  min-height: 100vh;
  padding-top: 10rem;
  width: '100%';
  height: 'auto'
}

.home-banner .left h1 {
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -3.5199999809265137px;
}

.v-img-section {
  min-height: 600px;
  border-radius: 15px;
  /*background: url('../public/assets/images/banners/vimgbanner.png') no-repeat center center/cover;*/
  width: '100%';
  height: 'auto'
}

.v-img-section1 {
  min-height: 349px;
  border-radius: 15px;
  /*background: url('../public/assets/images/banners/vimgbanner.png') no-repeat center center/cover;*/
  width: '100%';
  height: 'auto'
}

.card-video-container {
  border: 3px solid #ccc; /* Add initial border around the video container */
  border-radius: 10px; /* Add border radius for style */
  overflow: hidden; /* Ensure video doesn't overflow border */
  transition: border-width 0.5s ease, border-color 0.5s ease; /* Add transition for border width and color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.card-video-container:hover {
  border-color:var(--blue); /* Change border color on hover */
}


.card-video {
  width: 100%; /* Make the video responsive */
  height: auto; /* Ensure aspect ratio is maintained */
}


.exploring-section {
  min-height: 50vh;
  width: '100%';
  height: 'auto'
}

.exploring-section .header h1 {
  font-size: 55px;
  font-weight: 400;
  line-height: 70px;
  letter-spacing: -2.559999942779541px;
}

.exploring-section .header p {
  max-width: 770px;
  margin: auto;
  font-size: 19px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
}

.exploring-section .body .card {
  min-height: 400px;
  border-radius: 10px;
  padding: 25px 20px 25px 20px;
  box-shadow: 0px 5px 20px 0px #31004F14;
}

.card{
  width: 100% !important;
  flex-grow: 1;
}
.exploring-section .body .card h3 {
  /* font-family: Inter; */
  font-size: 25px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.6399999856948853px;
  text-align: left;
}

.exploring-section .body .card p {
  /* font-family: Inter; */
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.two-step-section {
  min-height: 60vh;
}

.two-step-section h1 {
  /* font-family: Inter; */
  font-size: 55px !important;
  font-weight: 400 !important;
  line-height: 70px;
  color: var(--black1);
  letter-spacing: -2.559999942779541px;
}

.contactus-section {
  min-height: 60vh;
}

.contactus-section h2 {
  /* font-family: Inter; */
  font-size: 50px !important;
  font-weight: 500 !important;
  line-height: 70px;
  color: var(--black1);
  letter-spacing: -3.5199999809265137px;
}

.contactus-section .contact-form {
  max-width: 600px;
  min-height: 400px;
  border-radius: 15px !important;
  box-shadow: 0px 5px 20px 0px #31004F14;
}




.contact-form .contact-form .label {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
}

.contact-form input {
  border-radius: 20px;
}

.contact-form textarea {
  resize: none;
  border-radius: 20px;
  border: 1px solid #DFDFDF;
}

/* ################################### HOME PAGE CSS ENDS ################################### */



/* ################################### ABOUT PAGE CSS STARTS ################################### */


.aboutus-banner {
  padding-top: 10rem;
}


.aboutus-banner h2 {
  /* font-family: Inter; */
  font-size: 50px !important;
  font-weight: 500 !important;
  line-height: 70px;
  text-align: left;
  letter-spacing: -3.5199999809265137px;
}

.agents-section .header h2 {
  /* font-family: Inter; */
  font-size: 50px;
  font-weight: 500;
  /* line-height: 70px; */
  letter-spacing: -3.5199999809265137px;
  text-align: center;

}

.agents-section .agent-card h3 {
  /* font-family: Inter; */
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -3.5199999809265137px;
  text-align: left;

}

/* ################################### SERVICES PAGE CSS ENDS ################################### */

.services-wrapper {
  padding-top: 10rem;
}


/* ################################### SERVICES PAGE CSS ENDS ################################### */

/* Utilities Classes */


/* COLORS */

.txt-blue {
  color: var(--blue) !important;
}


/* BUTTONS */


.outline-nav-btn {
  width: 100px;
  height: 44px;
  color: var(--blue) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  border: 1px solid var(--blue);
  border-radius: 20px;
  letter-spacing: 0em;
  transition: 1s;
}

.outline-nav-btn:hover {
  background-color: var(--blue);
  color: var(--white) !important;
}

@property --blue-nav-btn-border-angle-1 {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0deg;
}

@property --blue-nav-btn-border-angle-2 {
  syntax: "<angle>";
  inherits: true;
  initial-value: 90deg;
}

@property --blue-nav-btn-border-angle-3 {
  syntax: "<angle>";
  inherits: true;
  initial-value: 180deg;
}

:root {
  --blue-nav-btn-bright-blue: rgb(0, 100, 255);
  --blue-nav-btn-bright-green: rgb(0, 255, 0);
  --blue-nav-btn-bright-red: rgb(255, 0, 0);
  --blue-nav-btn-background: var(--blue);
  --blue-nav-btn-foreground: var(--white);
  --blue-nav-btn-border-size: 2px;
  --blue-nav-btn-border-radius: 20px;
}

@supports (color: color(display-p3 1 1 1)) {
  :root {
    --blue-nav-btn-bright-blue: color(display-p3 0 0.2 1);
    --blue-nav-btn-bright-green: color(display-p3 0.4 1 0);
    --blue-nav-btn-bright-red: color(display-p3 1 0 0);
  }
}

@keyframes blueNavBtnRotateBackground {
  to { --blue-nav-btn-border-angle-1: 360deg; }
}

@keyframes blueNavBtnRotateBackground2 {
  to { --blue-nav-btn-border-angle-2: -270deg; }
}

@keyframes blueNavBtnRotateBackground3 {
  to { --blue-nav-btn-border-angle-3: 540deg; }
}

.blue-nav-btn-wrapper {
  padding: var(--blue-nav-btn-border-size);
  border-radius: calc(var(--blue-nav-btn-border-radius) + var(--blue-nav-btn-border-size));
  background-image: conic-gradient(
    from var(--blue-nav-btn-border-angle-1) at 10% 15%,
    transparent,
    var(--blue-nav-btn-bright-blue) 10%,
    transparent 30%,
    transparent
  ),
  conic-gradient(
    from var(--blue-nav-btn-border-angle-2) at 70% 60%,
    transparent,
    var(--blue-nav-btn-bright-green) 10%,
    transparent 60%,
    transparent
  ),
  conic-gradient(
    from var(--blue-nav-btn-border-angle-3) at 50% 20%,
    transparent,
    var(--blue-nav-btn-bright-red) 10%,
    transparent 50%,
    transparent
  );
  animation:
    blueNavBtnRotateBackground 3s linear infinite,
    blueNavBtnRotateBackground2 8s linear infinite,
    blueNavBtnRotateBackground3 13s linear infinite;
  display: inline-block;
}

.blue-nav-btn {
  width: 100px;
  height: 44px;
  color: var(--blue-nav-btn-foreground) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  background-color: var(--blue-nav-btn-background);
  border-radius: var(--blue-nav-btn-border-radius);
  letter-spacing: 0em;
  border: none;
}

.login-btn {
  height: 40px;
  color: var(--white);
  border-radius: 20px;
  background-color: var(--blue);
  border: 1px solid var(--blue);
}

.box-shadow-sm {
  box-shadow: 0px 5px 20px 0px #31004F14;
}


.border-1-blue {
  border: 1px solid var(--blue)
}

/* MARGINS */


.my-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

/* BORDERS */

.border-r-15 {
  border-radius: 10px;
}

/* FONTS */
.font-40 {
  /* font-family: Inter; */
  font-size: 40px !important;
  font-weight: 500 !important;
  line-height: 55px;
  letter-spacing: 0px;

}

/* OTHERS */

.trans-navbar {
  background-color: rgba(255, 255, 255, 0.1) !important;
  /* Semi-transparent white background */
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  /* Semi-transparent white outline */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0px 5px 20px 0px rgba(49, 0, 79, 0.1) !important;
  backdrop-filter: blur(10px) !important;
}

.ex-card {
  transition: transform 1s;
  /* Apply transition specifically to transform property */
 
  
}


.ex-card:hover {
  transform: scale(0.88);
  /* Scale down to 90% of its original size */
  cursor: pointer;
  
}


.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.position-raltive {
  position: relative;
}


.border-input-1 {
  border: 1px solid #DFDFDF !important;
}

::placeholder,
input,
select {
  color: #C8BBCF !important;
}


.box-shadow-steps {
  box-shadow: 0px 5px 20px 0px #31004F14;
}


/* ################################# DASHBOARD CSS STARTS ################################# */



.main-content-area {
  padding: 5px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 99%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #333333;
  height: calc(100vh - 10px); /* Adjust the subtracted value as needed */
  overflow-y: auto;
  font-size: 2vw;
}

/* Dark Mode */
.dark-mode .main-content-area {
  padding: 5px;
  background-color: #2a2d3e;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 99%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #d1d5db;
  height: calc(100vh - 10px); /* Adjust the subtracted value as needed */
  overflow-y: auto;
  font-size: 2vw;
}

.dark-mode .main-content-area::-webkit-scrollbar {
  width: 8px;
}

.dark-mode .main-content-area::-webkit-scrollbar-track {
  background-color: #1e2129;
}

.dark-mode .main-content-area::-webkit-scrollbar-thumb {
  background-color: #4b5563;
  border-radius: 4px;
}

.dark-mode .main-content-area::-webkit-scrollbar-thumb:hover {
  background-color: #6b7280;
}

.dashboard-container {
  display: flex;
  transition: all 0.5s ease; /* Smooth transition for any changes */
  position: relative; /* Ensure relative positioning for absolute children */
}

.dashboard-sidebar {
  width: 300px; /* Define initial width */
  flex: 0 0 auto; /* Sidebar does not grow or shrink */
  transition: width 0.5s ease-in-out; /* Smooth transition for width */
}

/* This ensures the sidebar collapses to 10px and main content's margin adjusts accordingly */
.sidebar-collapsed .dashboard-sidebar {
  width: 4px; /* Collapse sidebar */
  transform: translateX(0); /* Bring the button back into view */
  
}
/* Base styles for light mode */
.toggle-sidebar-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 20px;
  left: 0;
  transform: translateX(-40%);
  color: rgb(11, 19, 246); /* Consider adjusting for better visibility in light mode */
  border: none;
  border-radius: 50%; /* Make the button round */
  width: 14px; /* Adjusted for consistent size */
  height: 14px; /* Set the height to match the width for a perfectly round button */
  cursor: pointer;
  transition: box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
  padding: 5px; /* Add padding to reduce the image size if necessary */
  background-color: #f0f0f0; /* Lighter background for light mode */
}


.toggle-sidebar-btn:hover {
  box-shadow: 0 0 15px #007bff, 0 0 5px #007bff, 0 0 7px #007bff; /* Glowing effect */
  background-color: #68a2ff; /* Darker background color on hover */
}

.toggle-sidebar-btn img {
  width: 100%; /* Ensure the image covers the button area */
  height: auto; /* Maintain aspect ratio */
}

/* Dark mode specific styles */
.dark-mode .toggle-sidebar-btn {
  background-color: #0787ff; /* Darker background for better contrast in dark mode */
  color: #fff; /* Lighter text color for contrast */
}

.dark-mode .toggle-sidebar-btn:hover {
  background-color: #ff9500; /* A darker shade of amber for interaction in dark mode */
  box-shadow: 0 0 15px #ff9500, 0 0 5px #ff9500, 0 0 7px #ff9500; /* Adjusted glowing effect for dark mode */
}

/* Base styles for light mode */
.download-file-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: rgb(11, 19, 246);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  font-size: 14px;
  margin-top: 10px;
}

.download-file-button:hover {
  box-shadow: 0 0 15px #007bff, 0 0 5px #007bff, 0 0 7px #007bff;
  background-color: #68a2ff;
}

/* Dark mode specific styles */
.dark-mode .download-file-button {
  background-color: #0787ff;
  color: #fff;
}

.dark-mode .download-file-button:hover {
  background-color: #ff9500;
  box-shadow: 0 0 15px #ff9500, 0 0 5px #ff9500, 0 0 7px #ff9500;
}


.dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: start; /* Align items to the start */
  padding: 10px; /* Provides internal spacing */
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  overflow: hidden; /* Ensures the pseudo-element is clipped to the nav-link bounds */
}

.dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px; /* Height of the underline */
  background-color: #007bff; /* Color of the underline */
  transform: translateX(-100%); /* Start from the left, hidden */
  transition: transform 0.3s ease-out; /* Smooth transition for the sliding effect */
}

.dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link:hover::after,
.dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link:focus::after {
  transform: translateX(0); /* Move underline to fully visible on hover/focus */
}

.light-mode .dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link {
  color: #333; /* Darker text for light mode */
  background-color: #f8f9fa; /* Light background */
}

.light-mode .dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link::after {
  background-color: #007bff; /* Underline color for light mode */
}

.dark-mode .dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link {
  color: #f8f9fa; /* Lighter text for dark mode */
  background-color: #343a40; /* Darker background */
}

.dark-mode .dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link::after {
  background-color: #17a2b8; /* Underline color for dark mode, choose a color that stands out */
}

/* Target the span inside .nav-link for the animation */
.dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link span {
  display: inline-block; /* Allows for transform translations */
  white-space: nowrap; /* Keeps the text in a single line */
  position: relative; /* Positioned relative to its normal position */
}

/* Define the scrolling animation */
@keyframes scrollText {
  from { transform: translateX(0%); }
  to { transform: translateX(-100%); } /* Move to the left */
}

.dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link:hover span,
.dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item .nav-link:focus span {
  animation: scrollText 5s linear forwards; /* Apply the scrolling animation on hover/focus */
}
.upload-progress-container {
  width: 100%;
  border-radius: 10px;
  margin: 10px 0;
  max-width: 300px;
  min-width: 200px;
}

@keyframes loading {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.upload-progress-bar {
  height: 15px; /* Increased height for a thicker progress bar */
  background-color: #4CAF50;
  border-radius: 10px;
  text-align: center;
  line-height: 15px; /* Adjusted line-height to vertically center the text */
  color: white;
  max-width: 300px;
  min-width: 200px;
  background-image: linear-gradient(45deg, #4CAF50 25%, #66bb6a 25%, #66bb6a 50%, #4CAF50 50%, #4CAF50 75%, #66bb6a 75%, #66bb6a 100%);
  background-size: 40px 40px;
  animation: loading 2s linear infinite;
  transition: width 1s ease-in-out;
  position: relative;
}

.upload-progress-bar::after {
  content: attr(data-progress) '%';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}


.buy-credits-button {
  width: 14px;
  height: 14px;
  padding: 4px;
  margin-right: 4px; /* Add some spacing between the button and text */
  z-index: 10;
}

.buy-credits-button img {
  width: 100%;
  height: 100%;
}

.icon-button:hover .fa-trash-alt {
  color: #d9534f; /* Change color on hover */
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px; /* Fixed width for the icon container */
  height: 30px; /* Fixed height for the icon container */
  margin-left: 10px; /* Ensure some space between title and icon */
  cursor: pointer; /* Changes the cursor to a pointer to indicate clickability */

}

.icon-container:hover { /* For SVG icons */
  color: #007bff; /* Change icon color on hover */
}


/* content box */
.content-box {
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  font-family: var(--font-family);
}

.content-box h1, .content-box h2, .content-box h3, .content-box h4, .content-box h5, .content-box h6 {
  color: var(--black); /* Use a darker color for headings */
  margin-top: 20px;
  margin-bottom: 10px;
}

.content-box p {
  margin: 0 0 20px 0;
  font-size: clamp(12px, 3.5vw, 16px); /* Adjusts between 12px and 16px based on viewport width */
  line-height: 1.5;
  color: var(--black2); /* Slightly lighter color for text */
}

.content-box ul, .content-box ol {
  padding-left: 20px;
  color: var(--caption); /* Color for list captions */
}

.content-box li {
  margin-bottom: 10px;
}

.light-mode .content-box {
  background: var(--white); /* Light background */
  color: var(--black3); /* Dark text for readability */
}

.light-mode .content-box h1, .light-mode .content-box h2, .light-mode .content-box h3, .light-mode .content-box h4, .light-mode .content-box h5, .light-mode .content-box h6 {
  color: var(--blue); /* Blue headings in light mode */
}

.dark-mode .content-box {
  background: var(--dark-blue); /* Dark background */
  color: var(--white2); /* Lighter text for readability */
}

.dark-mode .content-box h1, .dark-mode .content-box h2, .dark-mode .content-box h3, .dark-mode .content-box h4, .dark-mode .content-box h5, .dark-mode .content-box h6 {
  color: var(--dark-mode-blue); /* Distinct blue for headings in dark mode */
}

.dark-mode .content-box p, .dark-mode .content-box ul, .dark-mode .content-box ol, .dark-mode .content-box li {
  color: var(--white); /* Softer color for text elements in dark mode */
}

.user-info {
  position: relative; /* Ensure the container is positioned relative for overlay */
  padding: 20px; /* Add padding to create space between content and background */
  z-index: 10;
  
}

.user-info p{
  margin-left: 25px;
}

.background-image {
  --bg-width: 300px;
  --bg-height: 400px;
  --bg-size: cover;
  --bg-position: center;
  --bg-opacity: 0.25;
  --bg-top: -90px;
  --bg-left: -50px;

  position: absolute;
  top: 0;
  left: 0;
  width: var(--bg-width);
  height: var(--bg-height);
  background-image: url("../src/assets/ai.png");
  background-size: var(--bg-size);
  background-repeat: no-repeat;
  background-position: var(--bg-position);
  opacity: var(--bg-opacity);
  z-index: -1;
  margin-top: var(--bg-top);
  margin-left: var(--bg-left);;
}

.background-image2 {
  --bg-width: 350px;
  --bg-height: 450px;
  --bg-size: contain;
  --bg-position: center;
  --bg-opacity: 0.15;
  --bg-top: 20px;
  --bg-left: -20px;

  position: absolute;
  top: 0;
  left: 0;
  width: var(--bg-width);
  height: var(--bg-height);
  background-image: url("../src/assets/ai.png");
  background-size: var(--bg-size);
  background-repeat: no-repeat;
  background-position: var(--bg-position);
  opacity: var(--bg-opacity);
  z-index: 0;
  margin-top: var(--bg-top);
  margin-left: var(--bg-left);;

}

.dashboard-rightside {
  position: relative;
  display: flex;
  flex: 1;
  overflow-y: auto;
}




.dashboard-wrapper .main-content {
  flex-grow: 1; /* Takes up remaining space */
  overflow-y: auto; /* Allows vertical scrolling within main content */
}

.dashboard-wrapper .dashboard-sidebar {
  width: 300px;
  border-radius: 15px;
}


.dashboard-wrapper .dashboard-sidebar
.bottom-stuff {
  margin-top: 2rem;
}

.dashboard-wrapper .dashboard-sidebar
.bottom-stuff .blue-card {
  min-height: 170px;
  border-radius: 15px;
  background-color: var(--blue);
}


.dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item button {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 15px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  text-align: start !important;
}


.dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item button p {
  /* font-family: Inter; */
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0em;
}

.dashboard-wrapper .main-content .dashboard-header {
  width: 100%;
}

.dashboard-header {
  height: 60px; /* Example; adjust as needed */
}
.dashboard-header .dark-mode {
  height: 60px; /* Example; adjust as needed */
}
/* Base styles for light mode, which also apply to dark mode */
.tab-navigation {
  display: flex;
  padding: 10px;
  height: auto; /* Use auto height for flexibility */
  width: 95%; /* Full width for responsiveness */
  margin: 0 auto; /* Center alignment */

}

.tab-button {
  flex-grow: 1; /* Equal width for all tabs */
  background-color: var(--bg-color, #ffffff);
  border: 1px solid var(--border-color, #e0e0e0);
  border-bottom: none; /* Remove bottom border for seamless integration with tab content */
  margin-right: 2%;
  border-radius: 10px 10px 0 0; /* Rounded top corners */
  padding: 4px 5px;
  cursor: pointer;
  font-size: clamp(12px, 1.5vw, 16px); /* Responsive font size */
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  text-align: center;
  color: var(--text-color, #666);
}

.tab-button:not(:last-child) {
  margin-right: 2%;
}

.tab-button:hover,
.tab-button.active {
  background-color: var(--hover-bg-color, #f0f0f0);
  color: var(--active-text-color, #007bff);
}

.tab-button.active {
  border-bottom: 2px solid var(--active-border-color, #007bff);
  top: -2px;
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.2);
}

/* Dark mode specific overrides */
.dark-mode .tab-navigation {
  border-bottom-color: #343a40;
  background-color: #000;
}

.dark-mode .tab-button {
  background-color: #333;
  border-color: #444;
  color: #ccc;
}

.dark-mode .tab-button:hover,
.dark-mode .tab-button.active {
  background-color: #222;
  color: #fff;
}



/* ################################# Meeting Assistant Modal ################################# */


/* Shared styles for both light and dark modes */
.meeting-assistant-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.4); /* Light background for the frosted effect */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
}

.meeting-assistant-content {
  position:relative;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  z-index: 2; /* Ensure content is above the frosted overlay */
}

/* Inputs and buttons within the modal */
.meeting-assistant-content input,
.meeting-assistant-content select,
.meeting-assistant-content button {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.meeting-assistant-content button {
  background: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.meeting-assistant-content button:hover {
  background: #0056b3;
}

/* Light Mode Styles */
.light-mode .meeting-assistant-content {
  background: #fafafa;
  color: #333;
}

.light-mode .meeting-assistant-content button {
  background: #007bff;
}

/* Dark Mode Styles */
.dark-mode .meeting-assistant-modal {
  background-color: rgba(0, 0, 0, 0.6); /* Slightly darker overlay for dark mode with frosted effect */
}

.dark-mode .meeting-assistant-content {
  background: #222;
  color: #ccc;
}

.dark-mode .meeting-assistant-content input,
.dark-mode .meeting-assistant-content select {
  background: #333;
  color: #ccc;
  border: 1px solid #444;
}

.dark-mode .meeting-assistant-content button {
  background: #28a745;
}

.dark-mode .meeting-assistant-content button:hover {
  background: #218838;
}

.meeting-assistant-content .stripe-modal-close {
  position: absolute;
  top: 10px; /* Position it slightly from the top */
  right: 10px; /* Position it slightly from the right */
  background: #ccc; /* Light grey background, adjust as needed */
  color: #333; /* Darker text color for the '×', adjust as needed */
  border: none;
  font-size: 0.75rem; /* Adjust font size as needed */
  cursor: pointer;
  width: 20px; /* Set width for square shape */
  height: 20px; /* Set height for square shape */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px; /* Optional: adds slight rounding to corners */
}

/* ################################# Meeting Assistant Modal FIN ################################# */

.dashboard-wrapper .main-content .dashboard-header h2 {
  /* font-family: Inter; */
  font-size: 34px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.dashboard-wrapper .main-content .dashboard-header  {
  width: 422px;
  height: 61px;
  border-radius: 15px;
  box-shadow: 14px 17px 40px 4px #31004F14;
}

.header-icon {
  width: 24px; /* Example width */
  height: auto; /* Maintain aspect ratio */
}

.dashboard-wrapper .main-content .dashboard-header .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: none;
  border-right: none;
  border-bottom: 0;
  border-left: none;
  padding-right: 20px; /* Adjust the value as needed */
}
.dropdown-menu {
  left: auto;
  right: 0;
}


.rightside{
  width: clamp(250px, 260vw, 360px) ;
  margin-left: 0.255em;  
}

.search-input{
  min-width: 60px;
  max-width: 220px;
  width: clamp(60px, 20vw, 240px);
  border: none; /* Removes default browser styles */
  outline: none; /* Removes focus outline for a cleaner look */
  height: 100%; /* Ensures the input matches the height of its parent */
}

.dashboard-header  .search-type {
  width: clamp(100px, 20vw, 240px);

  border-radius: 10px;
  display: flex; /* This ensures the icon and input align nicely */
  align-items: center; /* Centers items vertically */
  height: 40px; /* Adjust based on your design needs */
}

.search-type input[type="text"] {
  width: clamp(80px, 50vw, 240px); /* Example values, adjust as needed */
}


.dashboard-header .rightside input {
  border: none;
  outline: none;
}

.dashboard-wrapper .main-content
.tab-content .card {
  margin-bottom: 1.2rem;
  border-radius: 15px !important;
  
}

.dashboard-wrapper .main-content
.tab-content .big-side-card {
  min-height: 680px;
}

.dashboard-wrapper .main-content
.tab-content .card h2{
  /* font-family: Inter; */
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;  
}


 .dashboard-wrapper .main-content .tab-content .card p{
  /* font-family: Inter; */
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.dashboard-wrapper .main-content 
.tab-content .card .big-input {
  border-radius: 15px;  
}

.common-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.common-questions ul {
  padding: 0;
  margin: 0;
  text-align: center;
}

.common-questions li {
  list-style: none; /* Remove default list styling */
  font-size: clamp(6px, 1.5vw, 12px); /* Adjusts between 12px and 16px based on viewport width */
}

.common-questions h5 {
  font-size: clamp(8px, 1.5vw, 12px); /* Adjusts between 12px and 16px based on viewport width */
  margin-bottom: 10px;
}

.common-questions .common-question-button {
  font-size: clamp(6px, 1.5vw, 20px); /* Adjusts between 12px and 16px based on viewport width */
  width: clamp(50px, 50vw, 2000px); /* Adjusts between 12px and 16px based on viewport width */
  padding: 1px 1px; /* Adjust padding as needed */
  font-weight: 700; /* Assuming buttons are bold */
  color: var(--blue); /* Text color */
  background-color: var(--white); /* Button background */
  border: 1px solid var(--blue); /* Border color */
  border-radius: 20px; /* Rounded borders */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  cursor: pointer; /* Change cursor to pointer to indicate clickable */
  text-align: center; /* Center button text */
  margin-bottom: -1px; /* Space between buttons */
}

.common-questions .common-question-button:hover {
  background-color: var(--blue); /* Background color on hover */
  color: var(--white); /* Text color on hover */
}

.chat-message {
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 8px;
  max-width: 99%;
  word-wrap: break-word;
  min-width: 180px;
  justify-content: center;  
  align-content: center;
}

.chat-history {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 15px;
  background-color: #f5f5f5; /* Very light grey background for the chat area */
  border-radius: 10px;
  width: 100%;  
  font-size: clamp(12px, 2vw, 16px); /* Adjusts between 12px and 16px based on viewport width */
  align-content:safe;
  min-width: 220px;
  height: 100%;
}


.box {
  width: 90%;
  height: 499px;
  background-color: var(--white); /* Adjusted background color */
  color: var(--black2); /* Adjusted text color */
  border-radius: 15px;
  box-shadow: 0px 5px 20px #31004F14;
  overflow-y: auto; /* Allows content to scroll inside the box */
  margin: 0 auto; /* Centers the box */
  padding: 20px; /* Adds internal padding around the content */
  text-align: justify; /* Justifies the text inside the box */
  overflow: hidden; /* Hides any overflow */
  text-overflow: ellipsis; /* Adds an ellipsis if the text overflows */
  border: 1px solid var(--gray1); /* Adds a border around the box */
}

.credits {
  color: var(--primary-color); /* Adjust color as needed */
  font-size: 14px; /* Adjust size as needed */
  /* Add more styling here if needed */
}

/* Icon Button Styles for a Modern Look with New Color Scheme */
.icon-button {
  border: none;
  padding: 10px;
  border-radius: 50%; /* Circular buttons */
  background-color: #4CAF50; /* Green for a vibrant look */
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-top: 30px; /* Push buttons down a bit more */
  color: #ffffff; /* This will set the text (and icon) color */
  z-index: 100;
}

.icon-button img {
  width: 24px; /* Adjust based on your icon size */
  transition: transform 0.2s; /* Smooth transition for transform */
  z-index: 100;
}

/* Hover and Focus effect */
.icon-button:hover,
.icon-button:focus {
  background-color: #3E8E41; /* A darker shade of green for interaction */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* Larger shadow on hover for 'lifting' effect */
  transform: translateY(-3px); /* Lift effect */
}

.transcription-title-container {
  flex-grow: 1;
  max-width: calc(100% - 120px); /* Adjust based on the button container's width */
  display: flex;
  align-items: center; /* Align the title or input vertically */
  
}

.transcription-title-input {
  width: 100%; /* Make the input field fill the container */
  border: 1px solid #ccc; /* Example border, adjust as needed */
  border-radius: 4px; /* Slightly rounded corners */
  padding: 4px 8px; /* Padding inside the input field */
  font-size: 16px; /* Match the font size of the title */
}

.transcription-title {
  white-space: nowrap; /* Prevents the title from wrapping */
  max-width: calc(100% - 120px); /* Start with a base max-width */
}

.transcription-date {
  display: block; /* Ensure it takes its own line */
  margin-top: 5px; /* Space between title and date */
  color: #6c757d; /* Light grey color, adjust as needed */
  font-size: 0.9rem; /* Smaller font size, adjust as needed */
}

.transcription-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative; /* Add this */
}

/* ################################# Trash and Edit Buttons Start ################################# */
.button-container {
  position: absolute;
  right: 0;
  top: 190%;
  transform: translateY(-50%);
  display: flex;
  gap: 8px;
  flex-shrink: 0;
}

.edit-button, .trash-button {
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
  background-color: transparent;
  border-radius: 50%;
  outline: none;
  width: 22px;
  height: 22px;
  color: #000; /* Kept black for default text color for visibility */
}

.edit-button {
  margin-right: 8px;
}

.trash-button img, .edit-button img {
  width: 14px;
  transition: transform 0.2s;
}

/* Hover and Focus effect */
.trash-button:hover, .trash-button:focus,
.edit-button:hover, .edit-button:focus {
  background-color: rgba(144, 19, 254, 0.2); /* Semi-transparent purple for hover/focus */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transform: translateY(-2px) scale(1.05);
  outline: none;
}

/* Adjust the icon color on hover */
.trash-button:hover img, .trash-button:focus img,
.edit-button:hover img, .edit-button:focus img {
  filter: brightness(1.2);
  outline: none;
}

.button-container .edit-button,
.button-container .trash-button {
  outline: none !important;
}

/* Light Mode Specific Styles */
.light-mode .trash-button, .light-mode .edit-button {
  background-color: #90CAF9; /* Light blue for a fresh, light mode look */
  color: #000;
}

.light-mode .trash-button:hover, .light-mode .edit-button:hover {
  background-color: #64B5F6; /* A shade darker blue for hover in light mode */
}

/* Dark Mode Specific Styles */
.dark-mode .trash-button, .dark-mode .edit-button {
  background-color: #2196F3; /* Deep purple for dark mode */
  color: #FFF; /* White text for contrast */
}

.dark-mode .trash-button:hover, .dark-mode .edit-button:hover {
  background-color: #9575CD; /* A lighter purple for hover in dark mode */
}
/* ################################# Trash and Edit Buttons End ################################# */


/* Dark mode specific styles */
.dark-mode .icon-button {
  background-color: #0787ff; /* Amber for dark mode contrast */
}
.dark-mode .icon-button:hover,
.dark-mode .icon-button:focus {
  background-color: #ff9500; /* A darker shade of amber for interaction */
}
/* Light mode specific styles */
.light-mode .icon-button {
  background-color: #2196F3; /* Blue for light mode */
}
.light-mode .icon-button:hover,
.light-mode .icon-button:focus {
  background-color: rgb(0, 4, 255); /* A darker shade of blue for interaction */
}
/* Styling for Font Awesome Icons within the button for default/light mode */
.icon-button .fa {
  color: #ffffff; /* Set the default color to white for visibility */
}
/* Hover and Focus effect for Font Awesome Icons */
.icon-button:hover .fa,
.icon-button:focus .fa {
  color: #ffffff; /* Ensure icon remains white on hover and focus */
}
/* Dark mode specific styles for Font Awesome Icons */
.dark-mode .icon-button .fa {
  color: #1e1e1e; /* Change color to dark for contrast against the amber background */
}

.dark-mode .icon-button:hover .fa,
.dark-mode .icon-button:focus .fa {
  color: #1e1e1e; /* Ensure icon color contrasts well on hover and focus in dark mode */
}

/* Light mode specific styles for Font Awesome Icons */
.light-mode .icon-button .fa {
  color: #ffffff; /* Maintain white color for light mode as well */
}

.light-mode .icon-button:hover .fa,
.light-mode .icon-button:focus .fa {
  color: #ffffff; /* Ensure icon remains white on hover and focus in light mode */
}

/* ################################# Transcription Table ################################# */


.transcription-table {
  font-family: 'Poppins', sans-serif; /* A modern, sans-serif font */
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.transcription-table th,
.transcription-table td {
  padding: clamp(4px, 1vw, 8px); /* Responsive padding */
  text-align: left;
  border: 1px solid #ddd; /* Default border color */
  font-size: clamp(12px, 1.5vw, 16px); /* Responsive font size */
}

.transcription-table tr:hover {
  background-color: #e8f4fd; /* Default hover state for rows */
}

.download-file-button {
  color: white;
  padding: clamp(8px, 1vw, 10px) clamp(16px, 2vw, 20px); /* Responsive padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: clamp(16px, 2vw, 20px); /* Responsive margin */
  transition: background-color 0.3s;
}

/* Light Mode Styles */
.light-mode .transcription-table th,
.light-mode .transcription-table td {
  border-color: #ddd;
}

.light-mode .transcription-table th {
  background-color: #f2f2f2;
}

.light-mode .transcription-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.light-mode .download-file-button {
  background-color: #6200ee; /* A hip purple */
}

.light-mode .download-file-button:hover {
  background-color: #3700b3;
}

/* Dark Mode Styles */
.dark-mode .transcription-table th,
.dark-mode .transcription-table td {
  border-color: #333;
}

.dark-mode .transcription-table th {
  background-color: #242424;
}

.dark-mode .transcription-table tr:nth-child(even) {
  background-color: #333333;
}

.dark-mode .transcription-table tr:hover {
  background-color: #434343; /* Dark mode row hover state */
}

.dark-mode .download-file-button {
  background-color: #bb86fc; /* A cool purple for dark mode */
}

.dark-mode .download-file-button:hover {
  background-color: #7c4dff;
}



/* ################################# DASHBOARD CSS ENDS ################################# */
/* DARK MODE CSS */
.dark-mode {
  background-color: var(--black);
  --soft-edge-color: rgba(0, 0, 0, 0.7);
  --hover-effect-shadow: rgba(255, 255, 255, 0.1);
}

.dark-mode p {
  color: var(--white2);
}

.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6 {

  color: var(--white) !important;
}
.dark-mode .dashboard-sidebar {
  background-color: var(--black3); /* Dark background */
  color: var(--white2); /* Light text */
}

.dark-mode .navbar li a {
  color: var(--white);
}

.dark-mode .navbar .active {
  color: var(--white) !important;
}

.dark-mode .card {
  background-color: var(--linear-g-blue);
}
.dark-mode .form-control{
  background-color: var(--linear-g-blue);
}

.dark-mode form input,
.dark-mode form select,
.dark-mode form textarea {
  background-color: var(--black3);
  border: 1px solid var(--black4) !important;
}

.dark-mode form input:focus,
.dark-mode form select:focus,
.dark-mode form textarea:focus {
  background-color: var(--black3);
  border: 1px solid var(--black4) !important;
  color: var(--white2) !important;
}

.dark-mode form button {
  border: 1px solid var(--blue) !important;
}

.dark-mode form label {
  color: var(--white) !important;
}

.dark-mode footer a {
  color: var(--white2);
}

.dark-mode .navbar .active {
  color: var(--white2);
}
.dark-mode .box h2 {
  background-color: #2a2a2a; /* Darker than the usual dark mode background */
  color: #fff; /* White text for contrast */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Consistent padding */
  margin: 10px auto; /* Centered with margin */
  width: auto; /* Adjusts to content size */
  max-width: 90%; /* Limits max width */
  box-shadow: 0 2px 4px rgba(255,255,255,0.1); /* Adjusted shadow for dark mode */
}

/* LIGHT MODE CSS */
.light-mode {
  background-color: var(--white);
}

.light-mode p {
  color: var(--black2);
}

.light-mode h1,
.light-mode h2,
.light-mode h3,
.light-mode h4,
.light-mode h5,
.light-mode h6 {
  color: var(--black1) !important;
}

.light-mode .navbar li a {
  color: var(--black1);
}

.light-mode .card {
  background-color: var(--white);
}

.light-mode form input,
.light-mode form select,
.light-mode form textarea {
  background-color: var(--white);
}

.light-mode form label {
  color: var(--black1) !important;
}

.light-mode footer a {
  color: var(--black1);
}

.light-mode .navbar .active {
  color: var(--black);
}

.form-control.big-input {
  resize: none;
  margin-top: 5px;
}

.form-control .big-input{
  min-width: 230px;
  height: clamp(40px, 15vw, 50px); /* Adjusts between 12px and 16px based on viewport width */
  font-size: clamp(8px,1.5px,20px);
  font-size: clamp(8px,1.5px,20px);
}

.dark-mode .box {
  background-color: var(--linear-g-blue); /* Dark background */
  color: var(--white2); /* Light text */
  border: 1px solid var(--black4);
}
.dark-mode .box h1,
.dark-mode .box h2,
.dark-mode .box h3,
.dark-mode .box h4,
.dark-mode .box h5,
.dark-mode .box h6,
.dark-mode .box p {
  color: var(--white); /* Ensure readable text in dark mode */
  text-align: center;
}

.light-mode .common-questions li button {
  color: var(--blue); /* Text color for light mode */
  background-color: var(--white); /* Button background for light mode */
  border: 1px solid var(--blue); /* Border color for light mode */
  font-size: clamp(8px, 1.5vw, 12px); /* Adjusts between 12px and 16px based on viewport width */

}

.light-mode .common-questions li button:hover {
  background-color: var(--blue); /* Hover background color for light mode */
  color: var(--white); /* Hover text color for light mode */
  
}


.dark-mode .chat-message.question {
  background-color: #2a2d3e; /* Darker shade for questions */
  border-left-color: #3A80EC;
}

.dark-mode .chat-message.answer {
  background-color: #1e2129; /* Darker shade for answers */
  border-left-color: #34a853;
}

.chat-message.question {
  background-color: var(--chat-question-light);
}

.chat-message.answer {
  background-color: var(--chat-answer-light);
}

.chat-history {
  background-color: var(--chat-bg-light);
}
/* Globe icon color for light mode */
.globe-icon-light {
  color: var(--blue); /* Adjust if you have a specific color in mind */
}

/* Dashboard Light Mode */
.light-mode .dashboard-wrapper .dashboard-sidebar
 .nav-tabs .nav-item button {
  margin-bottom: 1rem;
  border-radius: 15px;
  padding-top: 18px;
  padding-bottom: 18px;
  border: 1px solid var(--gray1) !important;
}

.light-mode .dashboard-wrapper .dashboard-sidebar
.nav-tabs .nav-item button.active {
  border: 1px solid var(--blue) !important;
  background: var(--linear-g-blue);  
}

.light-mode .dashboard-wrapper .dashboard-sidebar
 .nav-tabs .nav-item button p span {
  color: var(--black1);
}

.light-mode .dashboard-wrapper .dashboard-sidebar
 .nav-tabs .nav-item button small {
  color: var(--black2);
}

.light-mode .dashboard-wrapper .dashboard-sidebar
 .nav-tabs .nav-item button p i {
  color: var(--black2);
}
.dashboard-wrapper .dashboard-sidebar .nav-tabs .nav-item button.active i {
  color: var(--blue) !important;
}
.light-mode .dashboard-wrapper .dashboard-header .rightside .search-type,
.light-mode .dashboard-wrapper .dashboard-header .rightside input {
  background-color: var(--gray);
}

.light-mode .dashboard-header .rightside input {
  color: var(--black2) !important;
}

.light-mode .dashboard-wrapper
.dashboard-header .rightside .nav-item .txt-color {
  color: var(--black1);
}

.light-mode .dashboard-wrapper
.main-content .tab-content .card {
  box-shadow: 0px 5px 20px 0px #31004F14;
}

.light-mode .dashboard-wrapper .main-content 
.tab-content .card .big-input {
  color: var(--black1) !important; 
}

/* Light Mode - Assuming default styles are for light mode */
.chat-message.question {
  background-color: #e0e0e0; /* Light grey for questions */
  color: #000; /* Dark text for visibility */
}

.chat-message.answer {
  background-color: #ade2fb; /* Light blue for answers */
  color: #000; /* Dark text for visibility */
}

/* Dashboard Dark Mode */
.dark-mode .dashboard-wrapper .dashboard-sidebar
 .nav-tabs .nav-item button {
  margin-bottom: 1rem;
  border-radius: 15px;
  padding-top: 18px;
  padding-bottom: 18px;
  border: 1px solid var(--blue) !important;
}

.dark-mode .dashboard-wrapper .dashboard-sidebar
.nav-tabs .nav-item button.active {
  border: 1px solid var(--blue) !important;
  background: var(--linear-g-blue);  
}

.dark-mode .dashboard-wrapper .dashboard-sidebar
 .nav-tabs .nav-item button p span {
  color: var(--gray1);
}

.dark-mode .dashboard-wrapper .dashboard-sidebar
 .nav-tabs .nav-item button small {
  color: var(--gray1);
}

.dark-mode .dashboard-wrapper .dashboard-header .rightside .nav-item .nav-link,
.dark-mode .dashboard-wrapper .dashboard-header .rightside {
  color: var(--white) !important;
}

.dark-mode .dashboard-wrapper .dashboard-sidebar,
.dark-mode .dashboard-wrapper .dashboard-header .rightside {
  background-color: var(--black) !important;
}

.dark-mode .dashboard-wrapper .dashboard-header .rightside .search-type,
.dark-mode .dashboard-wrapper .dashboard-header .rightside .search-type input {
  background-color: var(--black);
}

/* Dark Mode Adjustments */
.dark-mode .chat-message.question {
  background-color: #2a2d3e; /* Darker shade for questions in dark mode */
  color: #fff; /* Light text for visibility */
}

.dark-mode .chat-message.answer {
  background-color: #1e2129; /* Darker shade for answers in dark mode */
  color: #fff; /* Light text for visibility */
}
/* Globe icon color for dark mode */
.globe-icon-dark {
  color: var(--white); /* Ensures good visibility in dark mode */
}
/* Dark mode overrides */
body.dark-mode {
  --background-color: var(--black3);
  --text-color: var(--white2);
  /* Override other variables for dark mode */
}

/* Cards */

.dark-mode .dashboard-wrapper
.main-content .tab-content .card{
  background-color: var(--dark-blue);
}

.dark-mode .dashboard-wrapper .main-content 
.tab-content .card .big-input {
  border: 1px solid var(--black2);
  background-color: var(--dark-blue);
}

.dark-mode .navbar {
  background-color: var(--dark-blue); /* or any dark color */
  color: var(--white); /* for text */
}

.light-mode .navbar {
  background-color: var(--white); /* or any light color */
  color: var(--black); /* for text */
}

.dashboard-sidebar {
  flex-shrink: 0; /* Prevents the sidebar from shrinking */
  width: 300px; /* Adjust width as necessary */
  height: 100vh; /* Ensures sidebar does not extend beyond viewport */
  overflow: hidden; /* Prevents sidebar from causing scroll */
  background-color: var(--white); /* Light background */
  color: var(--black1); /* Dark text */
}

/* Common styles for the modal, applied in both light and dark modes */
.whatsapp-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.whatsapp-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px; /* Adjust based on your preference */
}

.whatsapp-modal-content p {
  font-size: 18px; /* Increase font size for prominence */
  font-weight: bold; /* Make text bold */
  text-align: center; /* Center-align the text */
  margin: 0 0 20px 0;
  animation: fadeInUp 0.5s ease-out both; /* Apply animation */
}

.whatsapp-modal-content button {
  font-weight: bold; /* Make button text bold */
  display: block;
  margin: 20px auto 0; /* Adjust margin to space out from the text */
  padding: 10px 20px;
  background-color: #007bff; /* Primary color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.whatsapp-modal-content button:hover {
  background-color: #0056b3; /* Darken button on hover for interaction feedback */
}

/* Animation keyframes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.whatsapp-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow for depth */
  width: 90%;
  max-width: 400px;
  animation: fadeIn 0.5s ease-out both; /* Apply animation to the modal container */
}

/* Keyframes for modal container fadeIn effect */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Light mode specific styles */
.light-mode .whatsapp-modal-content {
  background-color: #f8f9fa; /* Light background */
  color: #212529; /* Dark text */
}

/* Dark mode specific styles */
.dark-mode .whatsapp-modal-content {
  background-color: #343a40; /* Dark background */
  color: #f8f9fa; /* Light text */
}

/* Soft Edge Effect with Scrollability */
.sidebar-links {
  position: relative;
  overflow-y: auto; /* Enable vertical scrolling */
  flex-grow: 1;
  padding-top: 20px; /* Add padding to accommodate the soft edge */
}

.sidebar-links::before {
  top: 0; /* Place at the top */
}

.sidebar-links::after {
  bottom: 0; /* Place at the bottom */
}

.sidebar-links::before {
  top: 0;
  background: linear-gradient(to bottom, var(--soft-edge-color), transparent);
}

.sidebar-links::after {
  bottom: 0;
  background: linear-gradient(to top, var(--soft-edge-color), transparent);
}

/* Hover Effect */
.sidebar-links button {
  transition: transform 0.3s, box-shadow 0.3s;
}

.sidebar-links button:hover {
  transform: translateY(-5px); /* Lift effect */
  box-shadow: 0 5px 15px var(--hover-effect-shadow); /* Shadow for depth */
}
.nav-link.selected {
  background-color: #f0f0f0; /* Light grey, for subtle indication */
  color: #333; /* Darker text for better contrast */
}
.dark-mode .nav-link.selected {
  background-color: #424242; /* Darker grey for dark mode */
  color: #f0f0f0; /* Lighter text for better contrast against the dark background */
}

/* Ensure the buttons at the bottom do not scroll away */
.bottom-stuff {
  flex-shrink: 0; /* Prevents shrinking */
}

/* Add this to ensure the delete icon click does not propagate */
.fa-trash-alt {
  cursor: pointer;
}

.dashboard-sidebar .nav-item .nav-link {
  white-space: normal; /* Allows text to wrap */
  text-overflow: ellipsis; /* Adds an ellipsis to text that can't fit */
  overflow: hidden; /* Prevents text from overflowing */
  display: block; /* Ensures the link takes up the full available width */
}

.dashboard-sidebar .nav-item {
  width: 100%; /* Ensures nav items do not overflow the sidebar width */
}

.dashboard-sidebar .nav-item p {
  margin-bottom: 0; /* Adjust as needed */
}

.dashboard-sidebar .nav-item span,
.dashboard-sidebar .nav-item small {
  display: inline-block; /* Allows text to wrap properly */
  max-width: 90%; /* Adjust based on your design, ensures space for the delete icon */
}

.dashboard-sidebar .nav-item i {
  float: right; /* Positions the delete icon to the right */
  margin-left: 10px; /* Adds space between text and icon */
}

.light-mode .box h2 {
  background-color: #f0f0f0; /* Light grey background */
  color: #333; /* Dark text for contrast */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Ample padding for emphasis */
  margin: 10px auto; /* Centered with margin */
  width: auto; /* Auto width based on content */
  max-width: 90%; /* Max width to maintain padding */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  text-align: center;
}

/* Dark Mode Styles for .common-questions li button */
.dark-mode .common-questions li button {
  color: var(--white); /* Text color for dark mode */
  background-color: var(--black2); /* Button background for dark mode */
  border: 1px solid var(--white2); /* Border color for dark mode, adjust if needed */
  font-size: clamp(8px, 1.5vw, 12px); /* Adjusts between 12px and 16px based on viewport width */
}

.dark-mode .common-questions li button:hover {
  background-color: var(--blue); /* Hover background color for dark mode */
  color: var(--white); /* Hover text color for dark mode */
}

/* ################################# Home Banner ################################# */
.globe-gif-container {
  width: 100%;
  height: auto;
}

.globe-gif {
  width: clamp(400px, 90%, 900px);
  height: auto;
}
@keyframes fadeInOut {
  0%, 100% { opacity: 0; transform: translateY(-20px) scale(0.9); }
  10%, 90% { opacity: 1; transform: translateY(0) scale(1); }
}

.banner-text {
  opacity: 0;
  animation: fadeInOut 20s infinite;
  animation-fill-mode: forwards;
  display: none; /* Initially hide all texts */
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.4em; /* Maintain the reduced font size */
  font-weight: 800; /* Adjust weight for Poppins characteristics */
  /* Updated linear gradient color */
  background: -webkit-linear-gradient(45deg, #007CF0, #00DFD8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-text.visible {
  display: block; /* Only show the active text */
}

.banner-text2 {
  opacity: 0;
  animation: fadeInOut 3s infinite;
  animation-fill-mode: forwards;
  display: none; /* Initially hide all texts */
  font-family: 'Noto Sans', sans-serif;
  font-size: 2em; /* Maintain the reduced font size */
  font-weight: 800; /* Adjust weight for Poppins characteristics */
  /* Updated linear gradient color */
  background: -webkit-linear-gradient(45deg, #007CF0, #00DFD8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-text2.visible {
  display: block; /* Only show the active text */
}

.banner-text3 {
  opacity: 0;
  animation: fadeInOut 3s infinite;
  animation-fill-mode: forwards;
  display: none; /* Initially hide all texts */
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.4em; /* Maintain the reduced font size */
  font-weight: 800; /* Adjust weight for Poppins characteristics */
  /* Updated linear gradient color */
  background:-webkit-linear-gradient(45deg, #ff9204, #ffb005);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-text3.visible {
  display: block; /* Only show the active text */
}

.dark-mode .banner-text3  {
  background:-webkit-linear-gradient(45deg, #ff9204, #ffb005);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dark-mode .banner-text3.visible {
  display: block; /* Only show the active text */
}

/* ################################# Carousel CSS STARTS ################################# */
.shadow-effect {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
border:1px solid #ECECEC;
  box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
}
#customers-testimonials .shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 300;
}
.testimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  background: #3190E7;
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0 9px 18px rgba(0,0,0,0.12), 0 5px 7px rgba(0,0,0,0.05);
}
#customers-testimonials .item {
  text-align: center;
  padding: 50px;
  margin-bottom:80px;
  opacity: .2;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#customers-testimonials .owl-item.active.center .item {
  opacity: 1;
  -webkit-transform: scale3d(1.0, 1.0, 1);
  transform: scale3d(1.0, 1.0, 1);
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  max-width: 90px;
  margin: 0 auto 17px;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
  background: #3190E7;
  transform: translate3d(0px, -50%, 0px) scale(0.7);
}
#customers-testimonials.owl-carousel .owl-dots{
display: inline-block;
width: 100%;
text-align: center;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot{
display: inline-block;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
  background: #3190E7;
  display: inline-block;
  height: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
  width: 20px;
}
/* ################################# Carousel CSS ENDS ################################# */



/* ################################# FOOTER CSS STARTS ################################# */

/* Modal backdrop */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

/* Modal content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: clamp(300px, 80%, 600px); /* Adjust width based on screen size */
  height: clamp(200px, 60%, 80vh); /* Adjust height based on screen size */
  max-height: 80vh; /* Ensure modal doesn't exceed 80% of viewport height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds modal height */
}


/* Dark Mode Styles */
.dark-mode .modal-content {
    background-color: #1F1F1F; /* Dark background */
    color: #CCCCCC; /* Light text for contrast */
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.1); /* Subtle shadow for depth */
}


/* Close button */
.modal-close-btn {
  display: block;
  margin-left: auto;
  padding: 5px 10px;
  cursor: pointer;
}

/* Light Mode Styles */
.priv.light-mode {
  background-color: #FFFFFF; /* White background */
  color: #333333; /* Dark text for contrast */
}

.priv.light-mode .privacy-policy-heading,
.priv.light-mode .section-heading,
.priv.light-mode .sub-section-heading,
.priv.light-mode .sub-sub-section-heading {
  color: #003366; /* Blue color for headings */
}

.priv.light-mode .privacy-policy-last-updated {
  color: #666666; /* Grey color for last updated info */
}

.priv.light-mode .privacy-policy-introduction,
.priv.light-mode .section-summary,
.priv.light-mode .sub-section-content,
.priv.light-mode .sub-sub-section-content {
  color: #333333; /* Dark text for content */
}

/* Dark Mode Styles */
.priv.dark-mode {
  background-color: #1F1F1F; /* Dark background */
  color: #000; /* Light text for contrast */
}

.priv.dark-mode .privacy-policy-heading,
.priv.dark-mode .section-heading,
.priv.dark-mode .sub-section-heading,
.priv.dark-mode .sub-sub-section-heading {
  color: #6EC6FF; /* Light blue color for headings */
}

.priv.dark-mode .privacy-policy-last-updated {
  color: #B0B0B0; /* Light grey color for last updated info */
}

.priv.dark-mode .privacy-policy-introduction,
.priv.dark-mode .section-summary,
.priv.dark-mode .sub-section-content,
.priv.dark-mode .sub-sub-section-content {
  color: #CCCCCC; /* Light text for content */
}


/* ################################# FOOTER CSS ENDS ################################# */


